import moment from 'moment';
import TimeField from 'react-simple-timefield';
import React, { useRef } from 'react';
import MaskedInput from 'antd-mask-input'
import  ReactToPrint   from 'react-to-print';
import { Typography, Row, Col, InputNumber, Space, Empty, Collapse, Alert, Tooltip, Card, Table, Divider, Form, Input, Select, Modal, Button, DatePicker, Tabs, List, Popconfirm, Descriptions } from 'antd';
import { FileSearchOutlined, StopOutlined, SearchOutlined, CheckOutlined, PlusOutlined, CloseOutlined, PrinterOutlined, CalendarOutlined } from '@ant-design/icons';

import Client_View from '../../img/icons/client_view.png';

moment.locale('ru');

const { TabPane } = Tabs;
const { TextArea } = Input;
const { Meta } = Card;
const { Panel } = Collapse;
const { Option, OptGroup } = Select;
const { Paragraph, Link, Title, Text } = Typography;
const Global = props => {

const [form1] = Form.useForm();
const [form2] = Form.useForm();
const [form3] = Form.useForm();
const [form4] = Form.useForm();
const [form5] = Form.useForm();
const [form6] = Form.useForm();
const [form7] = Form.useForm();
const [form8] = Form.useForm();
const [form9] = Form.useForm();
const [form10] = Form.useForm();
const [form11] = Form.useForm();
const [form12] = Form.useForm();
const [form13] = Form.useForm();
const [form14] = Form.useForm();
const [form15] = Form.useForm();
form2.setFieldsValue({ search: props.state.search });

const componentRef = useRef();
const componentRef2 = useRef();
const componentRef3 = useRef();

const columns1 = [
    {
        title: 'Телефон',
        dataIndex: 'phone',
        key: 'phone',
        render: (text) => <Link href={`tel:+${text}`}>{`+${text}`}</Link>,
        width: 100,
    },
    {
        title: 'ФИО',
        width: 100,
        render: (record) => (`${record.lastname.length > 0 ? record.lastname:''}${record.firstname.length > 0 ? ' '+record.firstname:'' }${record.patronymic.length > 0 ? ' '+record.patronymic:''}`)
    },
    {
        title: 'Бонусы',
        dataIndex: 'cashback',
        key: 'cashback',
        render: (text) => text,
        width: 100,
    }
];

    //* Отправка формы клиенты
    async function cliadd(v) {
        v['method'] = 'client_add';
        v['birthday'] = Date.parse(v['birthday'])/1000;
        v['phone'] = Number((v['phone']).replace(/\D+/g,""));
        await props.api(v,true);
    }

    //* Отправка формы клиенты
    async function patadd(v) {
        v['method'] = 'patient_add';
        await props.api(v,true);
    }

    //* Отправка формы
    async function notifadd(v) {
        v['method'] = 'client_notif_add';
        v['date'] = Date.parse(props.fulltime(v['date'],v['datet']))/1000;
        await props.api(v,true);
    }

    async function clipush(id) {
        await form1.resetFields();
        await props.api({
            'method':'client_get',
            'cliid':id
        },false);
        await props.panel('client_view');
    }

    var obj = {
        //* Поиск
        get clientlist () {
            if ( props.state.clients.length > 0 ) {
                let search = props.state.search.toLowerCase();
                return props.state.clients.filter( function( item ) {
                    return(
                        item['phone'].toLowerCase().indexOf( search ) > -1 ||
                        item['lastname'].toLowerCase().indexOf( search ) > -1 ||
                        item['firstname'].toLowerCase().indexOf( search ) > -1 ||
                        item['patronymic'].toLowerCase().indexOf( search ) > -1
                    );
                } );
            } else {
                return props.state.clients;
            }
        }
    }

    //* Отправка формы клиента
    async function clientupd(v) {
        v['method'] = 'client_upd';
        v['birthday'] = Date.parse(v['birthday'])/1000;
        v['phone'] = Number((v['phone']).replace(/\D+/g,""));
        await props.api(v,true);
    }

    //* Вызов окна добавления
    async function clipushadd() {
        await props.modal('cli_add');
    }

    //* Отправка формы пациента
    async function patientupd(v) {
        v['method'] = 'patient_upd';
        await props.api(v,true);
    }

    //* Отправка формы
    async function patientprop(v) {
        for( let value in v) {
            if (value.split('-')[0] === 'date') {
                v[value] = Date.parse(v[value])/1000;
            }
        }

        v['method'] = 'patient_prop';
        await props.api(v,true);
    }

    //* Отправка формы
    async function patientcard(v) {
        for( let value in v) {
            if (value.split('-')[0] === 'date') {
                v[value] = Date.parse(v[value])/1000;
            }
        }

        v['method'] = 'patient_card';
        await props.api(v,true);
    }

    //* Отправка формы
    async function recordadd(v) {
        v['method'] = 'record_add';
        v['date'] = Date.parse(props.fulltime(v['date'],v['datet']))/1000;
        await props.api(v,true);
    }

    //* Отправка формы
    async function recorddate(v) {
        v['method'] = 'record_date';
        v['date'] = Date.parse(props.fulltime(v['date'],v['datet']))/1000;
        await props.api(v,true);
    }

    //* Отправка формы
    async function recordedit(v) {
        v['method'] = 'record_edit';
        await props.api(v,true);
    }

    async function recorddone(v) {
        v['method'] = 'record_done';
        await props.api(v,true);
    }

    //* Отправка формы
    async function rec_add_inc(v) {
        v['method'] = 'rec_add_inc';
        await form10.resetFields();
        await props.api(v,true);
    }

    //* Отправка формы
    async function cashback(v) {
        v['method'] = 'cashback_upd';
        await form12.resetFields();
        await props.api(v,true);
    }

    //* Полное время 
    function fulltime(time) {
        let date = new Date(Number(time)*1000);
        let d1 = (date.getDate()<10?'0':'') + date.getDate();
        let d2 = date.getMonth()+1;
        d2 = (d2<10?'0':'') + d2;
        let d3 = date.getFullYear();
        let d4 = (date.getHours()<10?'0':'') + date.getHours();
        let d5 = (date.getMinutes()<10?'0':'') + date.getMinutes();
        return `${d3}/${d2}/${d1} ${d4}:${d5}`;
    }

    //* Вызов окна добавления
    async function patpushadd() {
        await form5.resetFields();
        await props.modal('patients_add');
        await form5.setFieldsValue({ 
            cliid:props.state.clientinfo.profile.id
        });
    }

    //* Вызов окна добавления
    async function notifpushadd() {
        await form11.resetFields();
        await props.modal('clinotif_add');
        await form11.setFieldsValue({ 
            cliid:props.state.clientinfo.profile.id
        });
    }

    //* Вызов окна обновления
    async function patpushview(id) {
        await form6.resetFields();
        await props.api({
            'method':'patient_get',
            'patid':id
        },false);
        await props.panel('patient_view');
    }

    //* Вызов окна
    async function recpushdone(id) {
        await props.modal('record_done');
    }

    //* Вызов окна обновления
    async function recordpushview(id) {
        await form9.resetFields();
        await props.api({
            'method':'record_get',
            'recordid':id,
            'cliid':props.state.clientinfo.profile.id, 
            'patid':props.state.patientinfo.profile.id
        },false);
        await props.panel('record_view');
    }

    const status = v => {
        switch(v) {
          case '0': return 'Запланирован';
          case '1': return 'Завершён';
          case '2': return 'Отменён';
          default: return 'Неизвестно';
        }
      }

      const recauthor = v => {
        switch(v) {
          case '1': return 'Автоматическая';
          case '2': return 'От врача';
          default: return 'Неизвестно';
        }
      }

      function disabledDate(current) {
        return current && current < moment().subtract(1, 'days').endOf('day');
      }

      function disabledDate2(current) {
        return current && current > moment().endOf('day');
      }

      async function proptimer() {
        props.setState({ moreload: true });
        form7.resetFields();
        await setTimeout(() => props.setState({ moreload: false }), 500);

      }

    return (
        <React.Fragment>
            <Modal className="modal-mini" width="100%" zIndex="960" key="m1" title="Просмотр приёма" visible={props.state.panel.indexOf( 'record_view' ) != -1 } onOk={() => props.panel('record_view')} onCancel={() => props.panel('record_view')} footer={null}>
                <div className="modal-mini-header">
                    <div className="modal-mini-header-logo"><img alt="" src={Client_View} /></div>
                    <div className="modal-mini-header-title title-big">Просмотр приёма</div>
                </div>
                <div className="modal-mini-body">
                    {!props.state.moreload || !props.state.loading ?
                        <React.Fragment>
                            {Object.keys(props.state.recordinfo).length > 0 ? (
                                <div className="tworow">
                                    {'data' in props.state.recordinfo ?
                                        <div>
                                            <Divider key="d1" >Информация о приёме</Divider>
                                            <div className="print" ref={componentRef}>
                                                <Descriptions title={`Приём № ${props.state.recordinfo.data.id}`} column={1}>
                                                    <Descriptions.Item label="Врач">{props.state.recordinfo.data.emp1_name}</Descriptions.Item>
                                                    <Descriptions.Item label="Администратор">{props.state.recordinfo.data.emp2_name}</Descriptions.Item>
                                                    <Descriptions.Item label="Услуга">{props.state.recordinfo.data.s_name}</Descriptions.Item>
                                                    <Descriptions.Item label="Заметка к услуге">{props.state.recordinfo.data.s_descr}</Descriptions.Item>
                                                    <Descriptions.Item label="Подробности">{props.state.recordinfo.data.complaint}</Descriptions.Item>
                                                    <Descriptions.Item label="Дата оформления">{props.lasttime(props.state.recordinfo.data.date1,'Неизвестно когда','full')}</Descriptions.Item>
                                                    <Descriptions.Item label="Дата приёма">{props.lasttime(props.state.recordinfo.data.date2,'Неизвестно когда','full')}</Descriptions.Item>
                                                    <Descriptions.Item label="Стоимость">{props.state.recordinfo.data.price > 0 ? props.state.recordinfo.data.price+'₽' : (props.state.recordinfo.data.price < 0 ? 'Бесплатно' : 'Не указано')}</Descriptions.Item>
                                                    <Descriptions.Item label="Статус приёма">{`${status(props.state.recordinfo.data.status)}`}</Descriptions.Item>
                                                </Descriptions>
                                                <Descriptions title={`Подробности`} column={1}>
                                                    <Descriptions.Item label="Что Вас привело?">{props.state.recordinfo.data.complaint}</Descriptions.Item>
                                                    <Descriptions.Item label="Консультировались ли по этому вопросу?">{props.state.recordinfo.data.rev_2}</Descriptions.Item>
                                                    <Descriptions.Item label="Пробовали ли самостоятельно решить вопрос?">{props.state.recordinfo.data.rev_3}</Descriptions.Item>
                                                </Descriptions>
                                                <Descriptions title={`Контактная информация`} column={1}>
                                                    <Descriptions.Item label="Тип пациента">{props.state.recordinfo.data.p_typen}</Descriptions.Item>
                                                    <Descriptions.Item label="Примечание">{props.state.recordinfo.data.p_breed}</Descriptions.Item>
                                                </Descriptions>
                                            </div>
                                            <div className="fcenter">
                                                <Space wrap>
                                                    <ReactToPrint
                                                        trigger={() => <Button className="des-button" type="dashed" shape="round" icon={<PrinterOutlined />} >Печать</Button>}
                                                        content={() => componentRef.current}
                                                    />
                                                    {props.state.recordinfo.data.status === "0" &&
                                                        <React.Fragment>
                                                            <Tooltip title={`Завершить`}>
                                                                <Button className="des-button" type="dashed" shape="round" icon={<CheckOutlined />} onClick={() => recpushdone()} >Завершить</Button>
                                                            </Tooltip>
                                                            <Button type="dashed" className="des-button" shape="round" icon={<FileSearchOutlined />} onClick={() => props.modal('record_edit')} >О приёме</Button>
                                                            <Button type="dashed" className="des-button" shape="round" icon={<CalendarOutlined />} onClick={() => props.modal('record_date')} >Перенести</Button>
                                                            <Tooltip title={`Отменить`}>
                                                                <Popconfirm placement="topRight" onConfirm={() => props.api({ 'method':'record_cancel', 'recordid':`${props.state.recordinfo.data.id}`, 'cliid':props.state.clientinfo.profile.id, 'patid':`${props.state.patientinfo.profile.id}` },true)} 
                                                                    title={
                                                                        <Space direction="vertical">
                                                                            <Text>Вы действительно хотите отменить данный приём?</Text>
                                                                        </Space>
                                                                    } 
                                                                    okText="Да" cancelText="Нет">
                                                                    <Button className="des-button" type="dashed" danger shape="round" icon={<CloseOutlined />} >Отменить</Button>
                                                                </Popconfirm>
                                                            </Tooltip>
                                                        </React.Fragment>
                                                    }
                                                </Space>
                                            </div>
                                        </div>
                                    :
                                        <Empty description="Информация о приёме недоступна" />
                                    }
                                    {'rec' in props.state.recordinfo ?
                                        <div>
                                            <div className="print" ref={componentRef2}>
                                                <Divider key="d1" >Рекомендации</Divider>
                                                {props.state.recordinfo.rec.length > 0 ?
                                                    <Tabs type="card">
                                                        <TabPane tab="Все" key="0">
                                                            <Title level={5}>Основные рекомендации</Title>
                                                            <List
                                                                locale={{ emptyText: 
                                                                    <Text type="secondary">Список рекомендаций пуст</Text>
                                                                }}
                                                                itemLayout="horizontal"
                                                                dataSource={props.state.recordinfo.rec.filter(function(item){return(item['type'] === '2')})}
                                                                renderItem={(item,index) => (
                                                                    <List.Item>
                                                                        <pre>{item.descr}</pre>
                                                                    </List.Item>
                                                                )}
                                                            />
                                                            <Title level={5}>Дополнительные рекомендации</Title>
                                                            <List
                                                                locale={{ emptyText: 
                                                                    <Text type="secondary">Список рекомендаций пуст</Text>
                                                                }}
                                                                itemLayout="horizontal"
                                                                dataSource={props.state.recordinfo.rec.filter(function(item){return(item['type'] === '1')})}
                                                                renderItem={(item,index) => (
                                                                    <List.Item>
                                                                        <pre>{item.descr}</pre>
                                                                    </List.Item>
                                                                )}
                                                            />
                                                        </TabPane>
                                                        <TabPane tab="От врача" key="2">
                                                            <List
                                                                locale={{ emptyText: 
                                                                    <Empty description='Список рекомендаций пуст' />
                                                                }}
                                                                itemLayout="horizontal"
                                                                dataSource={props.state.recordinfo.rec.filter(function(item){return(item['type'] === '2')})}
                                                                renderItem={(item,index) => (
                                                                    <List.Item>
                                                                        <pre>{item.descr}</pre>
                                                                    </List.Item>
                                                                )}
                                                            />
                                                        </TabPane>
                                                        <TabPane tab="Дополнительные" key="1">
                                                            <List
                                                                locale={{ emptyText: 
                                                                    <Empty description='Список рекомендаций пуст' />
                                                                }}
                                                                itemLayout="horizontal"
                                                                dataSource={props.state.recordinfo.rec.filter(function(item){return(item['type'] === '1')})}
                                                                renderItem={(item,index) => (
                                                                    <List.Item>
                                                                        <pre>{item.descr}</pre>
                                                                    </List.Item>
                                                                )}
                                                            />
                                                        </TabPane>
                                                    </Tabs>
                                                :
                                                    <Empty description="Список рекомендаций пуст" />
                                                }
                                            </div>
                                            <div className="fcenter" style={{marginTop: 20}}>
                                                    <Space>
                                                        <ReactToPrint
                                                            trigger={() => <Button className="des-button" type="dashed" shape="round" icon={<PrinterOutlined />} >Печать</Button>}
                                                            content={() => componentRef2.current}
                                                        />
                                                        <Button className="des-button" type="dashed" shape="round" onClick={() => props.modal('rec_add_ind')} icon={<PlusOutlined />}>Добавить личную рекомендацию</Button>
                                                    </Space>
                                            </div>
                                        </div>
                                    :
                                        <Empty description="Информация о рекомендациях недоступна" />
                                    }
                                </div>
                            ) : (
                                <Empty description="Информация о приёме недоступна" />
                            )}
                        </React.Fragment>
                    :
                        <div className="spincenter"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
                    }
                </div>
            </Modal>
            <Modal className="modal-mini" width="100%" zIndex="950" key="m1" title="Просмотр анкеты" visible={props.state.panel.indexOf( 'patient_view' ) != -1 } onOk={() => props.panel('patient_view')} onCancel={() => props.panel('patient_view')} footer={null}>
                <div className="modal-mini-header">
                <div className="modal-mini-header-logo"><img alt="" src={Client_View} /></div>
                    <div className="modal-mini-header-title title-big">Просмотр анкеты</div>
                </div>
                <div className="modal-mini-body">
                    {!props.state.moreload || !props.state.loading ?
                        <React.Fragment>
                            {Object.keys(props.state.patientinfo).length > 0 ? (
                                <div className="tworow">
                                    {'profile' in props.state.patientinfo ?
                                        <div>
                                            <Form name="form" form={form6} onFinish={patientupd} className="form" >
                                                {
                                                    form6.setFieldsValue({ 
                                                        cliid:props.state.clientinfo.profile.id,
                                                        patid:props.state.patientinfo.profile.id,
                                                        name:props.state.patientinfo.profile.name,
                                                        nametype:props.state.patientinfo.profile.nametype,
                                                        breed:props.state.patientinfo.profile.breed
                                                    })
                                                }
                                                <Divider key="d1" >Информация о пациенте</Divider>
                                                <Form.Item hidden name={`cliid`} label='ID' rules={[{ required: true, message: `Пожалуйста, введите ID!` }]}>
                                                    <Input />
                                                </Form.Item>
                                                <Form.Item hidden name={`patid`} label='ID' rules={[{ required: true, message: `Пожалуйста, введите ID!` }]}>
                                                    <Input />
                                                </Form.Item>
                                                <Form.Item  className="max400" name="name" label="Имя" rules={[{ required: true, message: 'Пожалуйста, укажите имя пациента!' }]}>
                                                    <Input />
                                                </Form.Item>
                                                <Form.Item  className="max400" name="nametype" label="Тип" rules={[{ required: true, message: 'Пожалуйста, укажите тип пациента!' }]}>
                                                    <Select showSearch filterOption={(input, option) =>option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                                        { props.state.patientst.map( (item, index) => 
                                                            <Option disabled={Number(item.block)} key={index} value={item.id}>{item.name}</Option>
                                                        ) }
                                                    </Select>
                                                </Form.Item>
                                                <Form.Item
                                                    noStyle
                                                    shouldUpdate={(prevValues, currentValues) => prevValues[`nametype`] !== currentValues[`nametype`]}
                                                >
                                                    {({ getFieldValue }) =>
                                                        (getFieldValue(`nametype`) !== props.state.patientinfo.profile.nametype)  ? (
                                                            <Alert className="max400" style={{marginBottom:20}} 
                                                            message={<React.Fragment><Text strong>Смена типа</Text> - обратите внимание</React.Fragment>}
                                                            description={
                                                                <Collapse ghost>
                                                                    <Panel header="Почему это важно?" key="1">
                                                                        Смена типа ведёт за собой смену рекомендаций и списка оказываемых услуг.
                                                                    </Panel>
                                                                    <Panel header="Если карточка пациента уже заполнена" key="2">
                                                                        Если ранее в карточке были указаны данные, которые характерны только для прошлого типа пациента - необходимо обнулять показания (или связаться со специалистом).
                                                                    </Panel>
                                                                </Collapse>
                                                            } type="warning" />
                                                        ) : null
                                                    }
                                                </Form.Item>
                                                <Form.Item  className="max400" name="breed" label="Примечание">
                                                    <Input />
                                                </Form.Item>
                                                <div className="fcenter">
                                                    <Space wrap>
                                                        <Form.Item>
                                                            <Button className="des-button" type="dashed" shape="round" htmlType="submit">Сохранить</Button>
                                                        </Form.Item>
                                                        {Number(props.state.patientinfo.profile.block) === 0 ?
                                                            <Button className="des-button" type="dashed" danger shape="round" onClick={() => props.api({ 'method':'patient_block', 'cliid':props.state.clientinfo.profile.id, 'patid':`${props.state.patientinfo.profile.id}` },false)} icon={<StopOutlined />}>Заблокировать</Button>
                                                        :
                                                            <Button className="des-button" type="dashed" shape="round" onClick={() => props.api({ 'method':'patient_block', 'cliid':props.state.clientinfo.profile.id, 'patid':`${props.state.patientinfo.profile.id}` },false)} icon={<CheckOutlined />}>Разблокировать</Button>
                                                        }
                                                    </Space>
                                                </div>
                                                <div className="fcenter" style={{marginTop:20}}>
                                                    <Space wrap>
                                                        <Form.Item>
                                                            <Button className="des-button" type="dashed" shape="round" onClick={() => props.modal('patient_card')}>Карточка пациента</Button>
                                                        </Form.Item>
                                                    </Space>
                                                </div>
                                            </Form>
                                        </div>
                                    :
                                        <Empty description="Информация о профиле недоступна" />
                                    }
                                    {'services' in props.state.patientinfo ?
                                        <div>
                                            <Divider key="d1" >Список приёмов</Divider>
                                            {props.state.patientinfo.services.length > 0 ?
                                                <Tabs type="card" className="hoverlist">
                                                    <TabPane tab="Открыт" key="1">
                                                        <List
                                                            locale={{ emptyText: 
                                                                <Empty description='Список приёмов пуст' />
                                                            }}
                                                            itemLayout="horizontal"
                                                            dataSource={props.state.patientinfo.services.filter(function(item){return(item['status'] === '0')})}
                                                            renderItem={(item,index) => (
                                                                <List.Item 
                                                                    onClick={() => recordpushview(item.id)}
                                                                >
                                                                    <List.Item.Meta
                                                                        title={<React.Fragment>{item.name}</React.Fragment>}
                                                                        description={<React.Fragment>{props.lasttime(item.date2,'Неизвестно когда','full')}</React.Fragment>}
                                                                    />
                                                                </List.Item>
                                                            )}
                                                        />
                                                    </TabPane>
                                                    <TabPane tab="Завершен" key="2">
                                                        <List
                                                            locale={{ emptyText: 
                                                                <Empty description='Список приёмов пуст' />
                                                            }}
                                                            itemLayout="horizontal"
                                                            dataSource={props.state.patientinfo.services.filter(function(item){return(item['status'] === '1')})}
                                                            renderItem={(item,index) => (
                                                                <List.Item
                                                                    onClick={() => recordpushview(item.id)}
                                                                >
                                                                    <List.Item.Meta
                                                                        title={<React.Fragment>{item.name}</React.Fragment>}
                                                                        description={<React.Fragment>{props.lasttime(item.date2,'Неизвестно когда','full')}</React.Fragment>}
                                                                    />
                                                                </List.Item>
                                                            )}
                                                        />
                                                    </TabPane>
                                                    <TabPane tab="Отменен" key="3">
                                                        <List
                                                            locale={{ emptyText: 
                                                                <Empty description='Список приёмов пуст' />
                                                            }}
                                                            itemLayout="horizontal"
                                                            dataSource={props.state.patientinfo.services.filter(function(item){return(item['status'] === '2')})}
                                                            renderItem={(item,index) => (
                                                                <List.Item
                                                                    onClick={() => recordpushview(item.id)}
                                                                >
                                                                    <List.Item.Meta
                                                                        title={<React.Fragment>{item.name}</React.Fragment>}
                                                                        description={<React.Fragment>{props.lasttime(item.date2,'Неизвестно когда','full')}</React.Fragment>}
                                                                    />
                                                                </List.Item>
                                                            )}
                                                        />
                                                    </TabPane>
                                                </Tabs>
                                            :
                                            <Empty description="Список оказанных услуг пуст" />
                                            }
                                            <div className="fcenter" style={{marginTop: 20}}>
                                                <Form.Item>
                                                    <Button className="des-button" type="dashed" shape="round" onClick={() => props.modal('record_add')} icon={<PlusOutlined />}>Новый приём</Button>
                                                </Form.Item>
                                            </div>
                                        </div>
                                    :
                                        <Empty description="Информация о услугах недоступна" />
                                    }
                                    {'rec' in props.state.patientinfo ?
                                        <div>
                                            <div className="print" ref={componentRef3}>
                                                <Divider key="d1" >Рекомендации</Divider>
                                                {props.state.patientinfo.rec.length > 0 ?
                                                    <List
                                                        locale={{ emptyText: 
                                                            <Empty description='Список рекомендаций пуст' />
                                                        }}
                                                        itemLayout="horizontal"
                                                        dataSource={props.state.patientinfo.rec}
                                                        renderItem={(item,index) => (
                                                            <List.Item>
                                                                <List.Item.Meta
                                                                    title={<React.Fragment>{item.name}</React.Fragment>}
                                                                    description={<React.Fragment><pre>{item.descr}</pre></React.Fragment>}
                                                                />
                                                            </List.Item>
                                                        )}
                                                    />
                                                :
                                                    <Empty description="Список рекомендаций пуст" />
                                                }
                                            </div>
                                            <div className="fcenter" style={{marginTop: 20}}>
                                                <Space>
                                                    <ReactToPrint
                                                        trigger={() => <Button className="des-button" type="dashed" shape="round" icon={<PrinterOutlined />} >Печать</Button>}
                                                        content={() => componentRef3.current}
                                                    />
                                                </Space>
                                            </div>
                                        </div>
                                    :
                                        <Empty description="Информация о рекомендациях недоступна" />
                                    }
                                </div>
                            ) : (
                                <Empty description="Информация о пациенте недоступна" />
                            )}
                        </React.Fragment>
                    :
                        <div className="spincenter"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
                    }
                </div>
            </Modal>
            <Modal className="modal-mini" width="100%" zIndex="900" key="m1" title="Просмотр анкеты" visible={props.state.panel.indexOf( 'client_view' ) != -1 } onOk={() => props.panel('client_view')} onCancel={() => props.panel('client_view')} footer={null}>
                <div className="modal-mini-header">
                    <div className="modal-mini-header-logo"><img alt="" src={Client_View} /></div>
                    <div className="modal-mini-header-title title-big">Просмотр анкеты</div>
                </div>
                <div className="modal-mini-body">
                    {!props.state.moreload || !props.state.loading ?
                        <React.Fragment>
                            {Object.keys(props.state.clientinfo).length > 0 ? (
                                <div className="tworow">
                                    {'profile' in props.state.clientinfo ?
                                        <div>
                                            <Form name="form" form={form1} onFinish={clientupd} className="form">
                                                { form1.setFieldsValue({ 
                                                    cliid:props.state.clientinfo.profile.id,
                                                    lastname:props.state.clientinfo.profile.lastname,
                                                    firstname:props.state.clientinfo.profile.firstname,
                                                    patronymic:props.state.clientinfo.profile.patronymic,
                                                    phone:props.state.clientinfo.profile.phone,
                                                    email:props.state.clientinfo.profile.email,
                                                    sex:props.state.clientinfo.profile.sex,
                                                    address:props.state.clientinfo.profile.address,
                                                    cashback:props.state.clientinfo.profile.cashback
                                                }) }
                                                {
                                                    Number(props.state.clientinfo.profile.birthday) > 0 ?
                                                    form1.setFieldsValue({ 
                                                        birthday:moment(fulltime(props.state.clientinfo.profile.birthday), "YYYY-MM-DD")
                                                    }) : 
                                                    form1.setFieldsValue({ birthday:null })
                                                }
                                                <Divider key="d1" >Контактные данные</Divider>
                                                <Form.Item hidden name={`cliid`} label='ID' rules={[{ required: true, message: `Пожалуйста, введите ID!` }]}>
                                                    <Input />
                                                </Form.Item>
                                                <Form.Item  className="max400" name="lastname" label="Фамилия" rules={[{ required: true, message: 'Пожалуйста, укажите фамилию клиента!' }]}>
                                                    <Input />
                                                </Form.Item>
                                                <Form.Item  className="max400" name="firstname" label="Имя" rules={[{ required: true, message: 'Пожалуйста, укажите имя клиента!' }]}>
                                                    <Input />
                                                </Form.Item>
                                                <Form.Item  className="max400" name="patronymic" label="Отчество" >
                                                    <Input />
                                                </Form.Item>
                                                <Form.Item  className="max400" name="birthday" label="Дата рождения" >
                                                    <DatePicker 
                                                        format="DD.MM.YYYY"
                                                        className="datepickerfull"
                                                    />
                                                </Form.Item>
                                                <Form.Item  className="max400" name="address" label="Адрес" >
                                                    <Input />
                                                </Form.Item>
                                                <Form.Item  className="max400" name="phone" label="Номер" rules={[{ required: true, message: 'Пожалуйста, укажите номер телефона клиента!' }]}>
                                                    <MaskedInput
                                                        prefix="+"
                                                        mask="7 111-111-11-11"
                                                        placeholder="7 999-999-99-99"
                                                        size="11"
                                                        formatCharacters={{
                                                        'W': {
                                                            validate(char) { return /\w/.test(char ) },
                                                            transform(char) { return char.toUpperCase() }
                                                        }
                                                        }}
                                                    />
                                                </Form.Item>
                                                <Form.Item  className="max400" name="email" label="E-mail" >
                                                    <Input />
                                                </Form.Item>
                                                <Form.Item  className="max400" name="sex" label="Пол" rules={[{ required: true, message: 'Пожалуйста, укажите пол клиента!' }]}>
                                                    <Select>
                                                        <Option value="0">Неизвестно</Option>
                                                        <Option value="1">Мужской</Option>
                                                        <Option value="2">Женский</Option>
                                                    </Select>
                                                </Form.Item>
                                                <Form.Item  className="max400" name="cashback" label="Бонусы" >
                                                    <Input disabled />
                                                </Form.Item>
                                                <div className="fcenter">
                                                    <Space wrap>
                                                        <Form.Item>
                                                            <Button className="des-button" type="dashed" shape="round" htmlType="submit">Сохранить</Button>
                                                        </Form.Item>
                                                        <Button className="des-button" type="dashed" shape="round" onClick={() => props.api({ 'method':'client_pas', 'cliid':props.state.clientinfo.profile.id },false)} >Выслать пароль</Button>
                                                        {Number(props.state.clientinfo.profile.block) === 0 ?
                                                            <Button className="des-button" type="dashed" danger shape="round" onClick={() => props.api({ 'method':'client_block', 'cliid':props.state.clientinfo.profile.id },false)} icon={<StopOutlined />}>Заблокировать</Button>
                                                        :
                                                            <Button className="des-button des-green" type="dashed" shape="round" onClick={() => props.api({ 'method':'client_block', 'cliid':props.state.clientinfo.profile.id },false)} icon={<CheckOutlined />}>Разблокировать</Button>
                                                        }
                                                    </Space>
                                                </div>
                                                <div className="fcenter" style={{marginTop:20}}>
                                                    <Space wrap>
                                                        <Form.Item>
                                                            <Button className="des-button" type="dashed" shape="round" onClick={() => props.modal('cashback')}>Бонусы</Button>
                                                        </Form.Item>
                                                    </Space>
                                                </div>
                                            </Form>
                                        </div>
                                    :
                                        <Empty description="Информация о профиле недоступна" />
                                    }
                                    {'patients' in props.state.clientinfo ?
                                        <div>
                                            <Divider key="d1" >Пациенты</Divider>
                                            { props.state.clientinfo.patients.length > 0 ?
                                                <Space wrap className="cardlist c_patients">
                                                    {props.state.clientinfo.patients.map( list => 
                                                        <Card 
                                                            bordered={true} 
                                                            style={{width:'100%'}}
                                                            key={list.id}
                                                            onClick={() => patpushview(list.id)}
                                                            hoverable
                                                        >
                                                            <Meta title={list.name} description={list.nametype} />
                                                        </Card>
                                                    )
                                                    }
                                                </Space>
                                            :
                                                <div className="fcenter"><Empty description="Список пуст" /></div>
                                            }
                                            <div className="fcenter" style={{marginTop:20}}>
                                                <Button className="des-button" type="dashed" shape="circle" icon={<PlusOutlined />} onClick={() => patpushadd()} />
                                            </div>
                                        </div>
                                    :
                                        <Empty description="Информация о пациентах недоступна" />
                                    }
                                    {'notif' in props.state.clientinfo ?
                                        <div>
                                            <Divider key="d1" >Напоминания</Divider>
                                            { props.state.clientinfo.notif.length > 0 ?
                                                <Tabs type="card">
                                                    <TabPane tab="Все" key="0">
                                                        <List
                                                            locale={{ emptyText: 
                                                                <Empty description='Список напоминаний пуст' />
                                                            }}
                                                            pagination={{ pageSize: 10 }}
                                                            itemLayout="horizontal"
                                                            dataSource={props.state.clientinfo.notif}
                                                            renderItem={(item,index) => (
                                                                <List.Item actions={[
                                                                    <Tooltip title={`Удалить`}>
                                                                        <Popconfirm placement="topRight" onConfirm={() => props.api({ 'method':'client_notif_del', 'clinotid':`${item.id}`, 'cliid':props.state.clientinfo.profile.id },true)} 
                                                                            title={
                                                                                <Space direction="vertical">
                                                                                    <Text>Вы действительно хотите удалить данное напоминание?</Text>
                                                                                </Space>
                                                                            } 
                                                                        okText="Да" cancelText="Нет">
                                                                            <Link type="danger" key="a2"><CloseOutlined /></Link>
                                                                        </Popconfirm>
                                                                    </Tooltip>
                                                                ]}>
                                                                    <List.Item.Meta
                                                                        title={<React.Fragment>{props.lasttime(item.date,'Неизвестно когда','full')}</React.Fragment>}
                                                                        description={<React.Fragment>{item.text}</React.Fragment>}
                                                                    />
                                                                </List.Item>
                                                            )}
                                                        />
                                                    </TabPane>
                                                    <TabPane tab="Активные" key="1">
                                                        <List
                                                            locale={{ emptyText: 
                                                                <Empty description='Список приёмов пуст' />
                                                            }}
                                                            itemLayout="horizontal"
                                                            dataSource={props.state.clientinfo.notif.filter(function(item){return(item['active'] === '1')})}
                                                            renderItem={(item,index) => (
                                                                <List.Item actions={[
                                                                    <Tooltip title={`Удалить`}>
                                                                        <Popconfirm placement="topRight" onConfirm={() => props.api({ 'method':'client_notif_del', 'clinotid':`${item.id}`, 'cliid':props.state.clientinfo.profile.id },true)} 
                                                                            title={
                                                                                <Space direction="vertical">
                                                                                    <Text>Вы действительно хотите удалить данное напоминание?</Text>
                                                                                </Space>
                                                                            } 
                                                                        okText="Да" cancelText="Нет">
                                                                            <Link type="danger" key="a2"><CloseOutlined /></Link>
                                                                        </Popconfirm>
                                                                    </Tooltip>
                                                                ]}>
                                                                    <List.Item.Meta
                                                                        title={<React.Fragment>{props.lasttime(item.date,'Неизвестно когда','full')}</React.Fragment>}
                                                                        description={<React.Fragment>{item.text}</React.Fragment>}
                                                                    />
                                                                </List.Item>
                                                            )}
                                                        />
                                                    </TabPane>
                                                    <TabPane tab="Прошлые" key="2">
                                                        <List
                                                            locale={{ emptyText: 
                                                                <Empty description='Список приёмов пуст' />
                                                            }}
                                                            itemLayout="horizontal"
                                                            dataSource={props.state.clientinfo.notif.filter(function(item){return(item['active'] === '0')})}
                                                            renderItem={(item,index) => (
                                                                <List.Item actions={[
                                                                    <Tooltip title={`Удалить`}>
                                                                        <Popconfirm placement="topRight" onConfirm={() => props.api({ 'method':'client_notif_del', 'clinotid':`${item.id}`, 'cliid':props.state.clientinfo.profile.id },true)} 
                                                                            title={
                                                                                <Space direction="vertical">
                                                                                    <Text>Вы действительно хотите удалить данное напоминание?</Text>
                                                                                </Space>
                                                                            } 
                                                                        okText="Да" cancelText="Нет">
                                                                            <Link type="danger" key="a2"><CloseOutlined /></Link>
                                                                        </Popconfirm>
                                                                    </Tooltip>
                                                                ]}>
                                                                    <List.Item.Meta
                                                                        title={<React.Fragment>{props.lasttime(item.date,'Неизвестно когда','full')}</React.Fragment>}
                                                                        description={<React.Fragment>{item.text}</React.Fragment>}
                                                                    />
                                                                </List.Item>
                                                            )}
                                                        />
                                                    </TabPane>
                                                </Tabs>
                                            :
                                                <div className="fcenter"><Empty description="Список пуст" /></div>
                                            }
                                            <div className="fcenter" style={{marginTop:20}}>
                                                <Button className="des-button" type="dashed" shape="circle" icon={<PlusOutlined />} onClick={() => notifpushadd()} />
                                            </div>
                                        </div>
                                    :
                                        <Empty description="Информация о напоминаниях недоступна" />
                                    }
                                </div>
                            ) : (
                                <Empty description="Информация о клиенте недоступна" />
                            )}
                        </React.Fragment>
                    :
                        <div className="spincenter"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
                    }
                </div>
            </Modal>
            <Modal className="modal-mini bg_chester" width={500} title="Управление бонусами" mask={false} visible={props.state.modal === 'cashback'} onOk={() => props.setState( { modal:'' } )} onCancel={() => {props.setState( { modal:'' } );}} footer={null}>
                <div className="modal-mini-header">
                    <div className="modal-mini-header-title">Управление бонусами</div>
                </div>
                <div className="modal-mini-body">
                    {!props.state.moreload || !props.state.loading ?
                        <React.Fragment>
                            {'profile' in props.state.clientinfo ?
                                <Form name="form" form={form12} onFinish={cashback} className="form" layout="vertical">
                                    {
                                            form12.setFieldsValue({ 
                                            cliid:props.state.clientinfo.profile.id
                                        })
                                    }
                                    <Form.Item className="max400" name='type' label='Действие' rules={[{ required: true, message: 'Пожалуйста, выберите действие!' }]} >
                                        <Select>
                                            <Option value="1">Начислить</Option>
                                            <Option value="2">Списать</Option>
                                        </Select>
                                    </Form.Item>
                                    <Form.Item className="max400" name="count" label="Количество" rules={[{ required: true, message: 'Пожалуйста, укажите количество!' }]} >
                                        <InputNumber style={{width:'100%'}} />
                                    </Form.Item>
                                    <Form.Item hidden name={`cliid`} label='ID' rules={[{ required: true, message: `Пожалуйста, введите ID!` }]}>
                                        <Input />
                                    </Form.Item>
                                    <div className="fcenter">
                                        <Form.Item>
                                            <Button className="des-button" type="dashed" shape="round" htmlType="submit">Сохранить</Button>
                                        </Form.Item>
                                    </div>
                                </Form>
                            :
                                <Empty description="Информация о рекомендации недоступна" />
                            }
                        </React.Fragment>
                    :
                        <div className="spincenter"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
                    }
                </div>
            </Modal>
            <Modal className="modal-mini bg_chester" width={500} title="Личная рекомендация" mask={false} visible={props.state.modal === 'rec_add_ind'} onOk={() => props.setState( { modal:'' } )} onCancel={() => {props.setState( { modal:'' } );}} footer={null}>
                <div className="modal-mini-header">
                    <div className="modal-mini-header-title">Личная рекомендация</div>
                </div>
                <div className="modal-mini-body">
                    {!props.state.moreload || !props.state.loading ?
                        <React.Fragment>
                            {'data' in props.state.recordinfo ?
                                <Form name="form" form={form10} onFinish={rec_add_inc} className="form" layout="vertical">
                                    {
                                            form10.setFieldsValue({ 
                                            cliid:props.state.clientinfo.profile.id,
                                            patid:props.state.patientinfo.profile.id,
                                            recordid:props.state.recordinfo.data.id,
                                        })
                                    }
                                    <Form.Item  className="max400" name="descr" label="" rules={[{ required: true, message: 'Пожалуйста, напишите рекомендацию!' }]} >
                                        <TextArea showCount maxLength={2000} rows={4} />
                                    </Form.Item>
                                    <Form.Item hidden name={`cliid`} label='ID' rules={[{ required: true, message: `Пожалуйста, введите ID!` }]}>
                                        <Input />
                                    </Form.Item>
                                    <Form.Item hidden name={`patid`} label='ID' rules={[{ required: true, message: `Пожалуйста, введите ID!` }]}>
                                        <Input />
                                    </Form.Item>
                                    <Form.Item hidden name={`recordid`} label='ID' rules={[{ required: true, message: `Пожалуйста, введите ID!` }]}>
                                        <Input />
                                    </Form.Item>
                                    <div className="fcenter">
                                        <Form.Item>
                                            <Button className="des-button" type="dashed" shape="round" htmlType="submit">Сохранить</Button>
                                        </Form.Item>
                                    </div>
                                </Form>
                            :
                                <Empty description="Информация о рекомендации недоступна" />
                            }
                        </React.Fragment>
                    :
                        <div className="spincenter"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
                    }
                </div>
            </Modal>
            <Modal className="modal-mini bg_chester" mask={false} width={500} title="Карточка пациента" visible={props.state.modal === 'patient_card'} onOk={() => props.setState( { modal:'' } )} onCancel={() => {props.setState( { modal:'' } );}} footer={null}>
                <div className="modal-mini-header">
                    <div className="modal-mini-header-title">Карточка пациента</div>
                </div>
                <div className="modal-mini-body">
                    {!props.state.moreload ?
                        <React.Fragment>
                            {'card' in props.state.patientinfo ?
                                <React.Fragment>
                                    {Object.keys(props.state.patientinfo['card']).length > 0 ? (
                                        <Form name="form15" form={form15} onFinish={patientcard} className="form" layout="vertical">
                                            {
                                                props.state.patientinfo.card.map(prop => {
                                                    return form15.setFieldsValue({ [`prop-${prop.id}`]:prop.status });
                                                })
                                            }
                                            {
                                                form15.setFieldsValue({ 
                                                    cliid:props.state.clientinfo.profile.id,
                                                    patid:props.state.patientinfo.profile.id
                                                })
                                            }
                                            { props.state.patientinfo.card.map( (prop, index) => 
                                                <React.Fragment>
                                                    <div>
                                                        <Form.Item key={prop.id} name={`prop-${prop.id}`} label={`${prop.review}`} >
                                                            <Select>
                                                                <Option value="0">Неизвестно</Option>
                                                                <Option value="1">Да</Option>
                                                                <Option value="2">Нет</Option>
                                                                <Option value="3">Процедура уже оказывается</Option>
                                                            </Select>
                                                        </Form.Item>
                                                    </div>
                                                    <Divider />
                                                </React.Fragment>
                                            ) }
                                            <Form.Item hidden name={`patid`} label='ID' rules={[{ required: true, message: `Пожалуйста, введите ID!` }]}>
                                                <Input />
                                            </Form.Item>
                                            <Form.Item hidden name={`cliid`} label='ID' rules={[{ required: true, message: `Пожалуйста, введите ID!` }]}>
                                                <Input />
                                            </Form.Item>
                                            <div className="fcenter">
                                                <Form.Item>
                                                    <Button className="des-button" type="dashed" shape="round" htmlType="submit">Сохранить</Button>
                                                </Form.Item>
                                            </div>
                                        </Form>
                                    ) : (
                                        <Empty description="Информация о карточке недоступна" />
                                    )}
                                </React.Fragment>
                            :
                                <Empty description="Информация о профиле недоступна" />
                            }
                        </React.Fragment>
                    :
                        <div className="spincenter"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
                    }
                </div>
            </Modal>
            <Modal className="modal-mini bg_chester" width={500} title="Завершение приёма" visible={props.state.modal === 'record_done'} onOk={() => props.setState( { modal:'' } )} onCancel={() => {props.setState( { modal:'' } );}} footer={null}>
                <div className="modal-mini-header">
                    <div className="modal-mini-header-title">Завершение приёма</div>
                </div>
                <div className="modal-mini-body">
                    {!props.state.moreload || !props.state.loading ?
                        <React.Fragment>
                            {'data' in props.state.recordinfo ?
                                <React.Fragment>
                                    <Form name="form" form={form13} onFinish={recorddone} className="form" layout="vertical">
                                        {
                                            form13.setFieldsValue({ 
                                                recordid:props.state.recordinfo.data.id,
                                                cliid:props.state.clientinfo.profile.id,
                                                patid:props.state.patientinfo.profile.id,
                                                cashback:props.state.clientinfo.profile.cashback
                                            })
                                        }
                                        <Form.Item hidden name={`recordid`} label='ID' rules={[{ required: true, message: `Пожалуйста, введите ID!` }]}>
                                            <Input />
                                        </Form.Item>
                                        <Form.Item hidden name={`cliid`} label='ID' rules={[{ required: true, message: `Пожалуйста, введите ID!` }]}>
                                            <Input />
                                        </Form.Item>
                                        <Form.Item hidden name={`patid`} label='ID' rules={[{ required: true, message: `Пожалуйста, введите ID!` }]}>
                                            <Input />
                                        </Form.Item>
                                        <Form.Item className="max400" name="cashback" label="Списать бонусы" >
                                            <InputNumber style={{width:'100%'}} min={0} max={props.state.clientinfo.profile.cashback} />
                                        </Form.Item>
                                        <div className="fcenter">
                                            <Form.Item>
                                                <Button className="des-button" type="dashed" shape="round" htmlType="submit">Завершить</Button>
                                            </Form.Item>
                                        </div>
                                    </Form>
                                </React.Fragment>
                            :
                                <Empty description="Информация о приёме недоступна" />
                            }
                        </React.Fragment>
                    :
                        <div className="spincenter"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
                    }
                </div>
            </Modal>
            <Modal className="modal-mini bg_chester" width={500} title="Новый приём" visible={props.state.modal === 'record_add'} onOk={() => props.setState( { modal:'' } )} onCancel={() => {props.setState( { modal:'' } );}} footer={null}>
                <div className="modal-mini-header">
                    <div className="modal-mini-header-title">Новый приём</div>
                </div>
                <div className="modal-mini-body">
                    {!props.state.moreload || !props.state.loading ?
                        <React.Fragment>
                            {'servicelist' in props.state.patientinfo ?
                                <React.Fragment>
                                    <Form name="form" form={form8} onFinish={recordadd} className="form" layout="vertical">
                                        {
                                            form8.setFieldsValue({ 
                                                cliid:props.state.clientinfo.profile.id,
                                                patid:props.state.patientinfo.profile.id,
                                                emp1:"0",
                                                emp2:"0",
                                                cashback:props.state.clientinfo.profile.cashback
                                            })
                                        }
                                        <Form.Item className="max400" name="service" label="Услуга" rules={[{ required: true, message: 'Пожалуйста, укажите услугу' }]}>
                                            <Select showSearch optionFilterProp="children" >
                                                { props.state.patientinfo.servicelist.map( (list, index) => 
                                                    <OptGroup key={index} label={list.name}>
                                                        { list['list'].map( item => 
                                                            <Option disabled={Number(item.block)} key={`option-${item.id}`} value={item.id}>
                                                                <div className="serv_col">
                                                                    <div>{`${item.descr.length > 0 ? `${item.descr}` : `${item.name}`}`}</div>
                                                                    <div>Стоимость: {item.price > 0 ? item.price : 'бесплатно'}</div>
                                                                </div>
                                                            </Option>
                                                        ) }
                                                    </OptGroup>
                                                ) }
                                            </Select>
                                        </Form.Item>
                                        <Form.Item className="max400" name="comment" label="Дополнительная информация" >
                                            <TextArea showCount maxLength={1000} rows={2} />
                                        </Form.Item>
                                        <Form.Item className="max400" name="date" label="Дата" rules={[{ required: true, message: 'Пожалуйста, укажите дату приёма!' }]}>
                                            <DatePicker
                                                format="DD.MM.YYYY"
                                                className="datepickerfull"
                                            />
                                        </Form.Item>
                                        <Form.Item name="datet" className="max400" label="Время">
                                            <TimeField
                                                colon=":"
                                                input={<Input />}
                                            />
                                        </Form.Item>
                                        <Form.Item className="max400" name="emp1" label="Врач" >
                                            <Select showSearch filterOption={(input, option) =>option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                                <Option value="0">Неизвестно</Option>
                                                { props.state.emp[1].map( (item, index) => 
                                                    <Option key={index} value={item.id}>{item.name}</Option>
                                                ) }
                                            </Select>
                                        </Form.Item>
                                        <Form.Item className="max400" name="emp2" label="Администратор" >
                                            <Select showSearch filterOption={(input, option) =>option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                                <Option value="0">Неизвестно</Option>
                                                { props.state.emp[0].map( (item, index) => 
                                                    <Option key={index} value={item.id}>{item.name}</Option>
                                                ) }
                                            </Select>
                                        </Form.Item>
                                        <Form.Item className="max400" name="complaint" label="Что Вас привело?" >
                                            <TextArea showCount maxLength={1000} rows={4} />
                                        </Form.Item>
                                        <Form.Item className="max400" name="rev_2" label="Консультировались ли по этому вопросу?" >
                                            <TextArea showCount maxLength={1000} rows={2} />
                                        </Form.Item>
                                        <Form.Item className="max400" name="rev_3" label="Пробовали ли самостоятельно решить вопрос?" >
                                            <TextArea showCount maxLength={1000} rows={3} />
                                        </Form.Item>
                                        <Form.Item hidden name={`patid`} label='ID' rules={[{ required: true, message: `Пожалуйста, введите ID!` }]}>
                                            <Input />
                                        </Form.Item>
                                        <Form.Item hidden name={`cliid`} label='ID' rules={[{ required: true, message: `Пожалуйста, введите ID!` }]}>
                                            <Input />
                                        </Form.Item>
                                        <div className="fcenter">
                                            <Form.Item>
                                                <Button className="des-button" type="dashed" shape="round" htmlType="submit">Сохранить</Button>
                                            </Form.Item>
                                        </div>
                                    </Form>
                                </React.Fragment>
                            :
                                <Empty description="Информация о профиле недоступна" />
                            }
                        </React.Fragment>
                    :
                        <div className="spincenter"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
                    }
                </div>
            </Modal>
            <Modal className="modal-mini bg_chester" width={600} title="Добавление клиента" visible={props.state.modal === 'cli_add'} onOk={() => props.setState( { modal:'' } )} onCancel={() => {props.setState( { modal:'' } );}} footer={null}>
                <div className="modal-mini-header">
                    <div className="modal-mini-header-title">Добавление клиента</div>
                </div>
                <div className="modal-mini-body">
                    {!props.state.moreload || !props.state.loading ?
                        <Form name="form" form={form3} onFinish={cliadd} className="form" layout="vertical">
                            <Form.Item  className="max400" name="lastname" label="Фамилия" rules={[{ required: true, message: 'Пожалуйста, укажите фамилию клиента!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item  className="max400" name="firstname" label="Имя" rules={[{ required: true, message: 'Пожалуйста, укажите имя клиента!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item  className="max400" name="patronymic" label="Отчество" >
                                <Input />
                            </Form.Item>
                            <Form.Item  className="max400" name="birthday" label="Дата рождения" >
                                <DatePicker 
                                    format="DD.MM.YYYY"
                                    className="datepickerfull"
                                />
                            </Form.Item>
                            <Form.Item  className="max400" name="address" label="Адрес" >
                                <Input />
                            </Form.Item>
                            <Form.Item  className="max400" name="phone" label="Номер" rules={[{ required: true, message: 'Пожалуйста, укажите номер телефона клиента!' }]}>
                                <MaskedInput
                                    prefix="+"
                                    mask="7 111-111-11-11"
                                    placeholder="7 999-999-99-99"
                                    size="11"
                                    formatCharacters={{
                                    'W': {
                                        validate(char) { return /\w/.test(char ) },
                                        transform(char) { return char.toUpperCase() }
                                    }
                                    }}
                                />
                            </Form.Item>
                            <Form.Item  className="max400" name="email" label="E-mail" >
                                <Input />
                            </Form.Item>
                            <Form.Item  className="max400" name="sex" label="Пол" rules={[{ required: true, message: 'Пожалуйста, укажите пол клиента!' }]}>
                                <Select>
                                    <Option value="0">Неизвестно</Option>
                                    <Option value="1">Мужской</Option>
                                    <Option value="2">Женский</Option>
                                </Select>
                            </Form.Item>
                            <Divider />
                            <div className="fcenter">
                                <Form.Item>
                                    <Button className="des-button" type="dashed" shape="round" htmlType="submit">Сохранить анкету</Button>
                                </Form.Item>
                            </div>
                        </Form>
                    :
                        <div className="spincenter"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
                    }
                </div>
            </Modal>
            <Modal className="modal-mini bg_chester" width={500} title="Добавление пациента" visible={props.state.modal === 'patients_add'} onOk={() => props.setState( { modal:'' } )} onCancel={() => {props.setState( { modal:'' } );}} footer={null}>
                <div className="modal-mini-header">
                    <div className="modal-mini-header-title">Добавление пациента</div>
                </div>
                <div className="modal-mini-body">
                    {!props.state.moreload || !props.state.loading ?
                        <Form name="form" form={form5} onFinish={patadd} className="form" layout="vertical">
                            <Form.Item hidden  className="max400" name="cliid" label="ID" rules={[{ required: true, message: 'Пожалуйста, укажите ID!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item  className="max400" name="name" label="Имя" rules={[{ required: true, message: 'Пожалуйста, укажите имя пациента!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item  className="max400" name="nametype" label="Тип" rules={[{ required: true, message: 'Пожалуйста, укажите тип пациента!' }]}>
                                <Select showSearch filterOption={(input, option) =>option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    { props.state.patientst.map( (item, index) => 
                                        <Option disabled={Number(item.block)} key={index} value={item.id}>{item.name}</Option>
                                    ) }
                                </Select>
                            </Form.Item>
                            <Form.Item  className="max400" name="breed" label="Примечание">
                                <Input />
                            </Form.Item>
                            <Divider />
                            <div className="fcenter">
                                <Form.Item>
                                    <Button className="des-button" type="dashed" shape="round" htmlType="submit">Сохранить</Button>
                                </Form.Item>
                            </div>
                        </Form>
                    :
                        <div className="spincenter"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
                    }
                </div>
            </Modal>
            <Modal className="modal-mini bg_chester" width={500} title="Добавление напоминания" visible={props.state.modal === 'clinotif_add'} onOk={() => props.setState( { modal:'' } )} onCancel={() => {props.setState( { modal:'' } );}} footer={null}>
                <div className="modal-mini-header">
                    <div className="modal-mini-header-title">Добавление напоминания</div>
                </div>
                <div className="modal-mini-body">
                    {!props.state.moreload || !props.state.loading ?
                        <Form name="form" form={form11} onFinish={notifadd} className="form" layout="vertical">
                            <Form.Item hidden  className="max400" name="cliid" label="ID" rules={[{ required: true, message: 'Пожалуйста, укажите ID!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item  className="max400" name="date" label="Дата отправки" rules={[{ required: true, message: 'Пожалуйста, укажите дату!' }]}>
                                <DatePicker
                                    format="DD.MM.YYYY"
                                />
                            </Form.Item>
                            <Form.Item name="datet" className="max400" label="Время">
                                <TimeField
                                    colon=":"
                                    input={<Input />}
                                />
                            </Form.Item>
                            <Form.Item  className="max400" name="text" label="" rules={[{ required: true, message: 'Пожалуйста, напишите напоминание!' }]} >
                                <TextArea showCount maxLength={100} rows={4} />
                            </Form.Item>
                            <Divider />
                            <div className="fcenter">
                                <Form.Item>
                                    <Button className="des-button" type="dashed" shape="round" htmlType="submit">Сохранить</Button>
                                </Form.Item>
                            </div>
                        </Form>
                    :
                        <div className="spincenter"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
                    }
                </div>
            </Modal>
            <Modal className="modal-mini bg_chester" width={500} mask={false} visible={props.state.modal === 'record_date'} onOk={() => props.setState( { modal:'' } )} onCancel={() => {props.setState( { modal:'' } );}} footer={null}>
                <div className="modal-mini-body">
                    {!props.state.moreload || !props.state.loading ?
                        <React.Fragment>
                            {'data' in props.state.recordinfo ?
                                <React.Fragment>
                                    <Form name="form" form={form9} onFinish={recorddate} className="form" layout="vertical">
                                        {
                                            form9.setFieldsValue({ 
                                                date:moment(fulltime(props.state.recordinfo.data.date2), "YYYY-MM-DD"),
                                                datet:moment(fulltime(props.state.recordinfo.data.date2)).format('HH:mm'),
                                                cliid:props.state.clientinfo.profile.id,
                                                patid:props.state.patientinfo.profile.id,
                                                recordid:props.state.recordinfo.data.id,
                                            })
                                        }
                                        <Form.Item  className="max400" name="date" label="Дата" rules={[{ required: true, message: 'Пожалуйста, укажите дату!' }]}>
                                            <DatePicker
                                                format="DD.MM.YYYY"
                                            />
                                        </Form.Item>
                                        <Form.Item name="datet" className="max400" label="Время">
                                            <TimeField
                                                colon=":"
                                                input={<Input />}
                                            />
                                        </Form.Item>
                                        <Form.Item hidden name={`cliid`} label='ID' rules={[{ required: true, message: `Пожалуйста, введите ID!` }]}>
                                            <Input />
                                        </Form.Item>
                                        <Form.Item hidden name={`patid`} label='ID' rules={[{ required: true, message: `Пожалуйста, введите ID!` }]}>
                                            <Input />
                                        </Form.Item>
                                        <Form.Item hidden name={`recordid`} label='ID' rules={[{ required: true, message: `Пожалуйста, введите ID!` }]}>
                                            <Input />
                                        </Form.Item>
                                        <div className="fcenter">
                                            <Form.Item>
                                                <Button className="des-button" type="dashed" shape="round" htmlType="submit">Сохранить</Button>
                                            </Form.Item>
                                        </div>
                                    </Form>
                                </React.Fragment>
                            :
                                <Empty description="Информация о дате недоступна" />
                            }
                        </React.Fragment>
                    :
                        <div className="spincenter"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
                    }
                </div>
            </Modal>
            <Modal className="modal-mini bg_chester" width={600} title="Информация о приёме" mask={false} visible={props.state.modal === 'record_edit'} onOk={() => props.setState( { modal:'' } )} onCancel={() => {props.setState( { modal:'' } );}} footer={null}>
                <div className="modal-mini-header">
                    <div className="modal-mini-header-title">Информация о приёме</div>
                </div>
                <div className="modal-mini-body">
                    {!props.state.moreload || !props.state.loading ?
                        <React.Fragment>
                            {'data' in props.state.recordinfo ?
                                <React.Fragment>
                                    <Form name="form" form={form14} onFinish={recordedit} className="form" layout="vertical">
                                        {
                                            form14.setFieldsValue({ 
                                                cliid:props.state.clientinfo.profile.id,
                                                patid:props.state.patientinfo.profile.id,
                                                recordid:props.state.recordinfo.data.id,
                                                complaint:props.state.recordinfo.data.complaint,
                                                rev_2:props.state.recordinfo.data.rev_2,
                                                rev_3:props.state.recordinfo.data.rev_3,
                                                comment:props.state.recordinfo.data.comment
                                            })
                                        }
                                        <Form.Item name="comment" label="Дополнительная информация к приёму" >
                                            <TextArea showCount maxLength={1000} rows={2} />
                                        </Form.Item>
                                        <Form.Item name="complaint" label="Что Вас привело?" >
                                            <TextArea showCount maxLength={1000} rows={4} />
                                        </Form.Item>
                                        <Form.Item name="rev_2" label="Консультировались ли по этому вопросу?" >
                                            <TextArea showCount maxLength={1000} rows={4} />
                                        </Form.Item>
                                        <Form.Item name="rev_3" label="Пробовали ли самостоятельно решить вопрос?" >
                                            <TextArea showCount maxLength={1000} rows={4} />
                                        </Form.Item>
                                        <Form.Item hidden name={`cliid`} label='ID' rules={[{ required: true, message: `Пожалуйста, введите ID!` }]}>
                                            <Input />
                                        </Form.Item>
                                        <Form.Item hidden name={`patid`} label='ID' rules={[{ required: true, message: `Пожалуйста, введите ID!` }]}>
                                            <Input />
                                        </Form.Item>
                                        <Form.Item hidden name={`recordid`} label='ID' rules={[{ required: true, message: `Пожалуйста, введите ID!` }]}>
                                            <Input />
                                        </Form.Item>
                                        <div className="fcenter">
                                            <Form.Item>
                                                <Button className="des-button" type="dashed" shape="round" htmlType="submit">Сохранить</Button>
                                            </Form.Item>
                                        </div>
                                    </Form>
                                </React.Fragment>
                            :
                                <Empty description="Информация о дате недоступна" />
                            }
                        </React.Fragment>
                    :
                        <div className="spincenter"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
                    }
                </div>
            </Modal>
            <Row className="wowload">
                <Col key="k2_clients" span={24} style={{marginBottom:10}} >
                    <div className="floattask"><span onClick={() => clipushadd()}></span></div>
                    <Space wrap>
                        <Form style={{maxWidth:'210px'}} name="form" form={form2} >
                            <Form.Item key="search" name="search"><Input placeholder="Поиск" suffix={<SearchOutlined />} onChange={ props.onChange } /></Form.Item>
                        </Form>
                    </Space>
                </Col>
                <Col key="k2_clients" span={24} className="shadowscreen" >
                    <Table 
                        className="table-padding"
                        locale={{ emptyText: <Empty description="Список пуст" />}} 
                        dataSource={obj.clientlist} 
                        scroll={{ x: 400 }} 
                        pagination={{ position: ['none', 'bottomLeft'] }}
                        rowKey="table1" 
                        columns={columns1}
                        onRow={(record, rowIndex) => {
                            return {
                            onClick: event => {clipush(record.id)}
                            };
                        }}
                    >
                    </Table>
                </Col>
            </Row>
        </React.Fragment>
    )
};

export default Global;