import React from 'react';
import MaskedInput from 'antd-mask-input'
import { Result, Tooltip, Form, Input, Button, Divider, Row, Col, Select, Typography } from 'antd';
import { InfoCircleOutlined, WalletOutlined } from '@ant-design/icons';

const { Text } = Typography
const { Option } = Select;
const Global = props => {
    const [form1] = Form.useForm();
    const [form2] = Form.useForm();
    const [form3] = Form.useForm();

    //* Отправка формы клиента
    async function sms_send(v) {
        await form2.resetFields();
        await props.setState( { client:[] } );
        v['method'] = 'sms_send';
        v['phone'] = Number((v['phone']).replace(/\D+/g,""));
        await props.api(v,true);
    }

    //@ Клиенты
    //* Отправка формы клиенты
    async function cliadd(v) {
        v['method'] = 'cli_add';
        v['phone'] = Number((v['phone']).replace(/\D+/g,""));
        await props.api(v,true);
    }

    //* Поиск клиента
    async function clisearch(v) {
        await props.setState({phone:v.phone});
        await form2.resetFields();
        await props.setState( { client:[] } );
        v['method'] = 'cli_search';
        v['phone'] = Number((v['phone']).replace(/\D+/g,""));
        await props.api(v,true);
    }

    const layout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
    };

    return (
        <React.Fragment>
            {props.state.success ? (
                <Result className="wowload"
                    status="success"
                    title="Сообщение добавлено в очередь отправки"
                    subTitle="SMS занесено в список отправленных" />
            ) : (
                <Row style={{flexDirection:'column',justifyContent:'center'}} className="column900 wowload">
                    <Col key="k1_search"  >
                        <Row className="fdcolumn">
                            <Col key="k1_search" className="shadowscreen" flex="1 1 300px" >
                                <div className="header_title">Поиск клиента</div>
                                <div style={{padding:20}} className="cli_sform" >
                                    <Form name="form" form={form1} onFinish={clisearch} className="form column-500" layout="inline">
                                        <Form.Item style={{flex:'1'}} name="phone" label="Номер" rules={[{ required: true, message: 'Пожалуйста, укажите номер телефона клиента!' }]}>
                                            <MaskedInput
                                                prefix="+"
                                                mask="7 111-111-11-11"
                                                placeholder="7 999-999-99-99"
                                                size="11"
                                                formatCharacters={{
                                                'W': {
                                                    validate(char) { return /\w/.test(char ) },
                                                    transform(char) { return char.toUpperCase() }
                                                }
                                                }}
                                            />
                                        </Form.Item>
                                        <Form.Item>
                                            <Button className="des-button" type="dashed" shape="round" htmlType="submit">Поиск</Button>
                                        </Form.Item>
                                    </Form>
                                </div>
                            </Col>
                            <Col key="k1_search" className="shadowscreen" flex="0 1 300px" style={{marginLeft:20}}>
                                <div className="fcenter cashblock" style={{padding:'20px 0'}}>
                                    <div className="cashicon">

                                    </div>
                                    <Text strong style={{margin:'10px 0'}} className="cashtitle">Баланс</Text>
                                    <Button shape="round" ><div><Text strong style={{color: '#5880ba'}} className="cashcount">{props.state.balance}</Text> ₽</div></Button>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    {Object.keys(props.state.client).length > 0 ? (
                        <Col key="k2_send" className="shadowscreen" >
                            { form2.setFieldsValue({ 
                                phone:props.state.client.phone,
                                lastname:props.state.client.lastname,
                                firstname:props.state.client.firstname,
                                patronymic:props.state.client.patronymic,
                                sex:props.state.client.sex
                            }) }
                            <Form name="form" form={form2} onFinish={sms_send} className="form" >
                                <div className="header_title">Отправка SMS</div>
                                <div style={{padding:20}} >
                                    <Form.Item  className="max500" name="phone" label="Номер" >
                                        <MaskedInput
                                            prefix="+"
                                            mask="7 111-111-11-11"
                                            placeholder="7 999-999-99-99"
                                            size="11"
                                            formatCharacters={{
                                            'W': {
                                                validate(char) { return /\w/.test(char ) },
                                                transform(char) { return char.toUpperCase() }
                                            }
                                            }}
                                        />
                                    </Form.Item>
                                    <Form.Item className="max500" name="lastname" label="Фамилия">
                                        <Input disabled />
                                    </Form.Item>
                                    <Form.Item className="max500" name="firstname" label="Имя">
                                        <Input disabled />
                                    </Form.Item>
                                    <Form.Item  className="max500" name="sex" label="Пол" >
                                        <Select disabled>
                                            <Option value="0">Неизвестно</Option>
                                            <Option value="1">Мужской</Option>
                                            <Option value="2">Женский</Option>
                                        </Select>
                                    </Form.Item>
                                    <Form.Item  className="max500" name="emp2" label="Администратор" >
                                        <Select>
                                                { props.state.emp2[0].map( (item, index) => 
                                                    <Option key={index} value={item.id}>{item.name}</Option>
                                                ) }
                                        </Select>
                                    </Form.Item>
                                    <Form.Item  className="max500" name="emp" label="Врач" >
                                        <Select>
                                                { props.state.emp2[1].map( (item, index) => 
                                                    <Option key={index} value={item.id}>{item.name}</Option>
                                                ) }
                                        </Select>
                                    </Form.Item>
                                    <Form.Item  className="max500" name="time" label="Отправить через" >
                                        <Select>
                                                { props.state.times.map( (item, index) => 
                                                    <Option key={index} value={item.id}>{item.text}</Option>
                                                ) }
                                        </Select>
                                    </Form.Item>
                                    <div className="fcenter">
                                        <Form.Item>
                                            <Button className="des-button" type="dashed" shape="round" htmlType="submit">Отправить</Button>
                                        </Form.Item>
                                    </div>
                                </div>
                            </Form>
                        </Col>
                     ) : (
                        <Col key="k3_add" className="shadowscreen" >
                            {form3.setFieldsValue({ phone:props.state.phone })}
                            <Form name="form" form={form3} onFinish={cliadd} className="form" >
                                <div className="header_title">Добавление клиента</div>
                                <div style={{padding:20}} >
                                    <Form.Item className="max500" name="phone" label="Номер" rules={[{ required: true, message: 'Пожалуйста, укажите номер телефона клиента!' }]}>
                                        <MaskedInput
                                            prefix="+"
                                            mask="7 111-111-11-11"
                                            placeholder="7 999-999-99-99"
                                            size="11"
                                            formatCharacters={{
                                            'W': {
                                                validate(char) { return /\w/.test(char ) },
                                                transform(char) { return char.toUpperCase() }
                                            }
                                            }}
                                        />
                                    </Form.Item>
                                    <Form.Item className="max500" name="lastname" label="Фамилия" rules={[{ required: true, message: 'Пожалуйста, укажите фамилию клиента!' }]}>
                                        <Input />
                                    </Form.Item>
                                    <Form.Item className="max500" name="firstname" label="Имя" rules={[{ required: true, message: 'Пожалуйста, укажите имя клиента!' }]}>
                                        <Input />
                                    </Form.Item>
                                    <Form.Item className="max500" name="patronymic" label="Отчество">
                                        <Input />
                                    </Form.Item>
                                    <Form.Item className="max500" name="sex" label="Пол" rules={[{ required: true, message: 'Пожалуйста, укажите пол клиента!' }]}>
                                        <Select>
                                            <Option value="0">Неизвестно</Option>
                                            <Option value="1">Мужской</Option>
                                            <Option value="2">Женский</Option>
                                        </Select>
                                    </Form.Item>
                                    <div className="fcenter">
                                        <Form.Item>
                                            <Button className="des-button" type="dashed" shape="round" htmlType="submit">Сохранить анкету</Button>
                                        </Form.Item>
                                    </div>
                                </div>
                                
                            </Form>
                        </Col>
                     )
                    }
                </Row>
            )}
        </React.Fragment>
    )
};

export default Global;