import React, { useRef } from 'react';
import  ReactToPrint   from 'react-to-print';
import { Typography, Row, Col, Space, Empty, Tooltip, Popconfirm, Table, Divider, Form, Input, Select, Modal, List, Rate, Button } from 'antd';
import { EyeOutlined, DeleteOutlined, SearchOutlined, PrinterOutlined } from '@ant-design/icons';

const { Option } = Select;
const { Link, Text, Title } = Typography;

const Global = props => {

    const [form1] = Form.useForm();
    const [form2] = Form.useForm();
    form2.setFieldsValue({ search: props.state.search });

    const componentRef = useRef();

    const columns1 = [
        {
            width: 1,
        },{
            title: 'Телефон',
            dataIndex: 'phone',
            key: 'phone',
            render: (text) => <Link href={`tel:+${text}`}><div className="m-r">{`+${text}`}</div></Link>,
            width: 100,
        },{
            title: 'ФИО',
            width: 100,
            render: (record) => <div className="m-r">{(`${record.lastname.length > 0 ? record.lastname:''}${record.firstname.length > 0 ? ' '+record.firstname:'' }${record.patronymic.length > 0 ? ' '+record.patronymic:''}`)}</div>
        },{
            title: 'Последнее SMS',
            dataIndex: 'date_sub',
            key: 'date_sub',
            render: (text) => <div className="m-r">{(text > 0 ? props.lasttime(text,'Не отправлено','last') : <Text className="bold" type="danger">—</Text>)}</div>,
            width: 100,
        },{
            title: 'Последний отзыв',
            dataIndex: 'date_com',
            key: 'date_com',
            render: (text) => <div className="m-r">{(text > 0 ? props.lasttime(text,'Не отправлен','last') : <Text className="bold" type="danger">—</Text>)}</div>,
            width: 100,
        }
    ];

    const columns2 = [
        {
            title: 'Отправлено',
            dataIndex: 'date_sub',
            key: 'date_sub',
            render: (text) => (text > 0 ? props.lasttime(text,'Не отправлено','last') : <Text className="bold" type="danger">—</Text>),
            width: 100,
            sorter: (a, b) => a.name.localeCompare(b.name),
        },{
            title: 'Пройдено',
            dataIndex: 'date_com',
            key: 'date_com',
            render: (text) => (text > 0 ? props.lasttime(text,'Не пройдено','mini') : <Text className="bold" type="danger">—</Text>),
            width: 100,
            sorter: (a, b) => a.name.localeCompare(b.name),
        },{
            title: 'Оценка',
            dataIndex: 'stars',
            key: 'stars',
            render: (text) => (text > 0 ? text : <Text className="bold" type="danger">—</Text>),
            width: 100,
            sorter: (a, b) => a.name.localeCompare(b.name),
        },{
            title: 'Действия',
            key: 'action',
            render: (record) => (
                <Space wrap key={record.id} size="middle">
                    {record.date_com > 0 &&
                        <Tooltip title={`Открыть информацию`}>
                            <Link key="a1" onClick={() => props.api({ 'method':'cli_stats', 'smsid':`${record.id}` },false)} ><EyeOutlined /></Link>
                        </Tooltip>
                    }
                    <Tooltip title={`Удалить`}>
                        <Popconfirm placement="topRight" onConfirm={() => props.api({ 'method':'sms_del', 'smsid':`${record.id}`, 'cliid':props.state.clientinfo[0].id },false)} 
                            title={
                                <Space direction="vertical">
                                    <Text>Вы действительно хотите удалить данную SMS?</Text>
                                    <Text>Все результаты, которые с ней связаны, также будут удалены.</Text>
                                </Space>
                            } 
                        okText="Да" cancelText="Нет">
                            <Link key="a2" type="danger"><DeleteOutlined /></Link>
                        </Popconfirm>
                    </Tooltip>
                </Space>
            ),
            width: 100,
        }
    ];

    async function clipush(id) {
        await props.setState( { clientinfo:[],clientstats:[] } );
        await props.api({
            'method':'cli_get',
            'cliid':id
        },false);
        props.modal('cli_view');
    }

    var obj = {
        //* Поиск
        get clientlist () {
            if ( props.state.clis.length > 0 ) {
                let search = props.state.search.toLowerCase();
                return props.state.clis.filter( function( item ) {
                    return(
                        item['phone'].toLowerCase().indexOf( search ) > -1 ||
                        item['lastname'].toLowerCase().indexOf( search ) > -1 ||
                        item['firstname'].toLowerCase().indexOf( search ) > -1 ||
                        item['patronymic'].toLowerCase().indexOf( search ) > -1
                    );
                } );
            } else {
                return props.state.clis;
            }
        }
    }

    return (
        <React.Fragment>
            <Modal width="100%" key="m1" title="Просмотр анкеты" visible={props.state.modal === 'cli_view'} onOk={() => props.setState( { modal:'' } )} onCancel={() => props.setState( { modal:'' } )} footer={null}>
                {!props.state.moreload || !props.state.loading ?
                    <React.Fragment>
                        {props.state.clientinfo.length > 0 ? (
                            <React.Fragment>
                                <Divider key="d1" orientation="left">Список SMS</Divider>
                                <Table pagination={{pageSize:5}} locale={{ emptyText: <Empty description="Список пуст" />}} dataSource={props.state.clientinfo[1]} scroll={{ x: 500 }} rowKey="table1" columns={columns2}></Table>
                                {Object.keys(props.state.clientstats).length > 0 && 
                                    <React.Fragment>
                                        <ReactToPrint
                                            trigger={() => <Button type="dashed" shape="round" icon={<PrinterOutlined />} >Печать</Button>}
                                            content={() => componentRef.current}
                                        />
                                        <div className="print" ref={componentRef}>
                                            <Divider key="d1" orientation="left">Подробности отзыва</Divider>
                                            <Space wrap direction="vertical">
                                                <Title level={5}>ФИО клиента: {`${props.state.clientinfo[0].lastname.length > 0 ? props.state.clientinfo[0].lastname:''}${props.state.clientinfo[0].firstname.length > 0 ? ' '+props.state.clientinfo[0].firstname:'' }${props.state.clientinfo[0].patronymic.length > 0 ? ' '+props.state.clientinfo[0].patronymic:''}`}</Title>
                                                <Title level={5}>Телефон клиента: {props.state.clientinfo[0].phone}</Title>
                                                <Title level={5}>Администратор: {props.state.clientstats.data.emp2}</Title>
                                                <Title level={5}>Сотрудник: {props.state.clientstats.data.emp1}</Title>
                                                <Title level={5}>SMS {Number(props.state.clientstats.data.sub) > 0 ? <Text>отправлено {props.lasttime(Number(props.state.clientstats.data.sub),'не отправлено','last')}</Text> : <Text className="bold" type="danger">—</Text>}</Title>
                                                <Title level={5}>Опрос {Number(props.state.clientstats.data.com) > 0 ? <Text> пройден {props.lasttime(Number(props.state.clientstats.data.com),'не пройден','mini')}</Text> : <Text className="bold" type="danger">—</Text>}</Title>
                                                <Title level={5}>Общая оценка: <Rate disabled value={Number(props.state.clientstats.data.rate)} /></Title>
                                            </Space>
                                            {props.state.clientstats.rev instanceof Array ? (
                                                <List
                                                    itemLayout="horizontal"
                                                    dataSource={props.state.clientstats.rev}
                                                    renderItem={itemlist => (
                                                    <List.Item>
                                                        <List.Item.Meta
                                                        title={<Text>» {itemlist['text']}</Text>}
                                                        description={
                                                            <Space wrap>
                                                                {
                                                                    itemlist.answers.map( (list, index) => 
                                                                        <React.Fragment>
                                                                            {
                                                                                itemlist.type === '4' ? (
                                                                                    <Rate disabled value={Number(list.text)} />
                                                                                ) : (
                                                                                    <div className="listanswer">{list.text}</div>
                                                                                )
                                                                            }
                                                                        </React.Fragment>
                                                                    )
                                                                }
                                                            </Space>
                                                        }
                                                        />
                                                    </List.Item>
                                                    )}
                                                />
                                            ) : (
                                                <Empty />
                                            )}
                                        </div>
                                    </React.Fragment>
                                }
                            </React.Fragment>
                        ) : (
                            'Пусто'
                        )}
                    </React.Fragment>
                :
                    <div className="spincenter"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
                }
            </Modal>
            <Row style={{flexDirection:'column'}} className="wowload column900">
                <Col key="k2_clients" span={24} style={{marginBottom:20}} >
                    <Form style={{maxWidth:'350px'}} name="form" form={form2} >
                        <Form.Item key="search" name="search"><Input placeholder="Поиск" prefix={<SearchOutlined style={{color: '#326e96'}} />} size="large" onChange={ props.onChange } /></Form.Item>
                    </Form>
                </Col>
                <Col key="k2_clients" span={24} className="shadowscreen" >
                    <Table className="yescolor" locale={{ emptyText: <Empty description="Список пуст" />}} dataSource={obj.clientlist} scroll={{ x: 800 }} rowKey="table1" columns={columns1} pagination={{ position: ['none', 'bottomLeft'] }}
                    onRow={(record, rowIndex) => {
                        return {
                        onClick: event => {clipush(record.id)}
                        };
                    }}
                    ></Table>
                </Col>
            </Row>
        </React.Fragment>
    )
};

export default Global;