import React from 'react';
import {  } from 'antd';
import {  } from '@ant-design/icons';
import Logo_View from '../../img/logo.svg';

const Global = props => {
    return (
        <div className="headerfull">
            <div className="logoheader">
                <img src={Logo_View} className="logo" />
            </div>
        </div>
    )
};

export default Global;