import moment from 'moment';
import TimeField from 'react-simple-timefield';
import React, { useRef } from 'react';
import  ReactToPrint   from 'react-to-print';
import { Typography, Row, Col, Space, Empty, Tooltip, Card, Table, Popover, Form, Input, Select, Modal, Button, DatePicker, Tabs, List, Popconfirm, Divider } from 'antd';
import { StopOutlined, SearchOutlined, CheckOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';

moment.locale('ru');

const { TabPane } = Tabs;
const { TextArea } = Input;
const { Meta } = Card;
const { Option } = Select;
const { Paragraph, Link, Title, Text } = Typography;
const Global = props => {

    const [form1] = Form.useForm();
    const [form2] = Form.useForm();
    const [form3] = Form.useForm();
    const [form4] = Form.useForm();
    const [form5] = Form.useForm();
    const [form6] = Form.useForm();
    const [form7] = Form.useForm();
    const [form8] = Form.useForm();
    const [form9] = Form.useForm();
    const [form10] = Form.useForm();
    const [form11] = Form.useForm();
    const [form12] = Form.useForm();
    form2.setFieldsValue({ search: props.state.search });

    const componentRef = useRef();
    const componentRef2 = useRef();
    const componentRef3 = useRef();

    let { filteredInfo } = props.state;
    filteredInfo = filteredInfo || {};
    
    var obj = {
        //* Поиск
        get consults () {
            if ( props.state.consults.length > 0 ) {
                let search = props.state.search.toLowerCase();
                return props.state.consults.filter( function( item ) {
                    return(
                        item['phone'].toLowerCase().indexOf( search ) > -1 ||
                        item['client_lastname'].toLowerCase().indexOf( search ) > -1 ||
                        item['client_firstname'].toLowerCase().indexOf( search ) > -1 ||
                        item['client_patronymic'].toLowerCase().indexOf( search ) > -1
                    );
                } );
            } else {
                return props.state.consults;
            }
        }
    }
    
    function titleblock(n) {
        switch(n) {
          case 'unpaid': return <div className="cal-badge cal-badge-open">Ожидается оплата</div>;
          case 'pending': return <div className="cal-badge cal-badge-warning">Рассматривается</div>;
          case 'waiting_for_capture': return <div className="cal-badge cal-badge-warning">Подтверждается</div>;
          case 'succeeded': return <div className="cal-badge cal-badge-close">Оплачено</div>;
          case 'done': return <div className="cal-badge cal-badge-close">Состоялась</div>;
          case 'canceled': return <div className="cal-badge cal-badge-danger">Отменён</div>;
          default: return <div className="cal-badge">Неизвестно</div>;
        }
    }

    const titlefilter = n => {
        switch(n) {
            case 'unpaid': return 'Ожидается оплата';
            case 'pending': return 'Рассматривается';
            case 'waiting_for_capture': return 'Подтверждается';
            case 'succeeded': return 'Оплачено';
            case 'done': return 'Состоялась';
            case 'canceled': return 'Отменён';
            default: return 'Неизвестно';
          }
      }
    
    const columns1 = [
        {
            title: 'Телефон',
            dataIndex: 'phone',
            key: 'phone',
            render: (text) => <Link href={`tel:+${text}`}>{`+${text}`}</Link>,
            width: 100,
        },
        {
            title: 'ФИО',
            width: 100,
            render: (record) => (`${record.client_lastname.length > 0 ? record.client_lastname:''}${record.client_firstname.length > 0 ? ' '+record.client_firstname:'' }${record.client_patronymic.length > 0 ? ' '+record.client_patronymic:''}`)
        },
        {
            title: 'Дата',
            dataIndex: 'date',
            key: 'date',
            render: (text) => props.lasttime(text,'Неизвестно когда','full'),
            width: 100,
        },
        {
            title: 'Статус',
            dataIndex: 'status',
            key: 'status',
            render: (text) => titleblock(text),
            width: 100,
            filters: obj.consults.map(function(item){return {text:titlefilter(item.status),value:item.status}}).map(JSON.stringify).reverse().filter(function (e, i, a) {return a.indexOf(e, i+1) === -1;}).reverse().sort().map(JSON.parse),
            filteredValue: filteredInfo.status || null,
            onFilter: (value, record) => record.status.includes(value),
            sorter: (a, b) => a.status.localeCompare(b.status),
        }
    ];

    async function conpush(id) {
        await form1.resetFields();
        await props.api({
            'method':'consult_get',
            'conid':id
        },false);
        await props.panel('consult_view');
    }

    //* Отправка формы
    async function conupd(v) {
        v['method'] = 'consult_upd';
        await props.api(v,true);
    }

    //* Полное время 
    function fulltime(time) {
        let date = new Date(Number(time)*1000);
        let d1 = (date.getDate()<10?'0':'') + date.getDate();
        let d2 = date.getMonth()+1;
        d2 = (d2<10?'0':'') + d2;
        let d3 = date.getFullYear();
        let d4 = (date.getHours()<10?'0':'') + date.getHours();
        let d5 = (date.getMinutes()<10?'0':'') + date.getMinutes();
        return `${d3}/${d2}/${d1} ${d4}:${d5}`;
    }

    const layout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
    };

    return (
        <React.Fragment>
            <Modal className="modal-mini" width="100%" zIndex="900" key="m1" title="Онлайн-консультация" visible={props.state.panel.indexOf( 'consult_view' ) != -1 } onOk={() => props.panel('consult_view')} onCancel={() => props.panel('consult_view')} footer={null}>
                <div className="modal-mini-header">
                    <div className="modal-mini-header-title">Онлайн-консультация</div>
                </div>
                <div className="modal-mini-body">
                    {!props.state.moreload || !props.state.loading ?
                        <React.Fragment>
                            {Object.keys(props.state.consultinfo).length > 0 ? (
                                <div className="tworow">
                                    {'id' in props.state.consultinfo ?
                                        <div>
                                            <Divider key="d1" >Контактные данные</Divider>
                                            <Form {...layout} style={{padding:24}} name="form" form={form1} onFinish={conupd} className="form">
                                                {
                                                    form1.setFieldsValue({
                                                        client_lastname:props.state.consultinfo.client_firstname,
                                                        client_firstname:props.state.consultinfo.client_firstname,
                                                        client_patronymic:props.state.consultinfo.client_patronymic,
                                                        phone:props.state.consultinfo.phone,
                                                        patient_name:props.state.consultinfo.patient_name,
                                                        patient_type:props.state.consultinfo.patient_type,
                                                        patient_breed:props.state.consultinfo.patient_breed
                                                    })
                                                }
                                                <Form.Item name="client_lastname" label="Фамилия" >
                                                    <Input disabled />
                                                </Form.Item>
                                                <Form.Item name="client_firstname" label="Имя" >
                                                    <Input disabled />
                                                </Form.Item>
                                                <Form.Item name="client_patronymic" label="Отчество" >
                                                    <Input disabled />
                                                </Form.Item>
                                                <Form.Item name="phone" label="Номер" >
                                                    <Input prefix="+" disabled />
                                                </Form.Item>
                                                <Form.Item name="patient_name" label="Имя пациента" >
                                                    <Input disabled />
                                                </Form.Item>
                                                <Form.Item name="patient_type" label="Тип" >
                                                    <Input disabled />
                                                </Form.Item>
                                                <Form.Item name="patient_breed" label="Примечание" >
                                                    <Input disabled />
                                                </Form.Item>
                                            </Form>
                                        </div>
                                    :
                                        <Empty description="Информация о профиле недоступна" />
                                    }
                                    {'id' in props.state.consultinfo ?
                                        <div>
                                            <Divider key="d1" >Информация о заявке</Divider>
                                            <div className="fcenter">
                                                {titleblock(props.state.consultinfo.status)}
                                            </div>
                                            <Form {...layout} style={{padding:24}} name="form" form={form3} onFinish={conupd} className="form">
                                                {
                                                    form3.setFieldsValue({
                                                        conid:props.state.consultinfo.id,
                                                        money:props.state.consultinfo.money ? props.state.consultinfo.money : 'Неизвестно',
                                                        theme:props.state.consultinfo.theme ? props.state.consultinfo.theme : 'Не указано',
                                                        descr:props.state.consultinfo.descr ? props.state.consultinfo.descr : 'Не указано',
                                                        type:props.state.consultinfo.theme ? 
                                                            `${props.state.consultinfo.cons_theme} - ${props.state.consultinfo.cons_name}`
                                                        :
                                                            'Не указано',
                                                        doctor_id:props.state.consultinfo.doctor_id,
                                                        answer:props.state.consultinfo.answer,
                                                    })
                                                }
                                                <Form.Item hidden name={`conid`} label='ID' rules={[{ required: true, message: `Пожалуйста, введите ID!` }]}>
                                                    <Input disabled />
                                                </Form.Item>
                                                <Form.Item name="type" label="Тип консультации" >
                                                    <Input disabled />
                                                </Form.Item>
                                                <Form.Item name="money" label="Стоимость" >
                                                    <Input disabled />
                                                </Form.Item>
                                                <Form.Item name="theme" label="Тема" >
                                                    <Input disabled />
                                                </Form.Item>
                                                <Form.Item name="descr" label="Суть проблемы" >
                                                    <TextArea disabled rows={4} />
                                                </Form.Item>
                                                <Form.Item name="doctor_id" label="Врач" >
                                                    <Select showSearch filterOption={(input, option) =>option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                                        <Option value="0">Неизвестно</Option>
                                                        { props.state.emp[1].map( (item, index) => 
                                                            <Option key={index} value={item.id}>{item.name}</Option>
                                                        ) }
                                                    </Select>
                                                </Form.Item>
                                                <Form.Item name="answer" label="Ответ врача" >
                                                    <TextArea rows={4} showCount maxLength={5000} />
                                                </Form.Item>
                                                <div className="fcenter">
                                                    <Space wrap>
                                                        <Form.Item>
                                                            <Button className="des-button" type="dashed" shape="round" htmlType="submit">Сохранить</Button>
                                                        </Form.Item>
                                                        {props.state.consultinfo.status === 'succeeded' &&
                                                            <Tooltip title={`Завершить`}>
                                                                <Popconfirm placement="topRight" onConfirm={() => props.api({ 'method':'consult_done', 'conid':props.state.consultinfo.id },false)}
                                                                    title={
                                                                        <Space direction="vertical">
                                                                            <Text>Вы действительно хотите завершить данную заявку?</Text>
                                                                        </Space>
                                                                    } 
                                                                    okText="Да" cancelText="Нет">
                                                                    <Button className="des-button" type="dashed" shape="round" icon={<CheckOutlined />} >Завершить</Button>
                                                                </Popconfirm>
                                                            </Tooltip>
                                                        }
                                                    </Space>
                                                </div>
                                            </Form>
                                        </div>
                                    :
                                        <Empty description="Информация о заявке недоступна" />
                                    }
                                </div>
                            ) : (
                                <Empty description="Информация о клиенте недоступна" />
                            )}
                        </React.Fragment>
                    :
                        <div className="spincenter"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
                    }
                </div>
            </Modal>
            <Row className="wowload">
                <Col key="k2_clients" span={24} style={{marginBottom:10}} >
                    <Space wrap>
                        <Form style={{maxWidth:'210px'}} name="form" form={form2} >
                            <Form.Item key="search" name="search"><Input placeholder="Поиск" suffix={<SearchOutlined />} onChange={ props.onChange } /></Form.Item>
                        </Form>
                    </Space>
                </Col>
                <Col key="k2_clients" span={24} className="shadowscreen" >
                    <Table 
                        onChange={props.htc}
                        className="table-padding"
                        locale={{ emptyText: <Empty description="Список пуст" />}} 
                        dataSource={obj.consults} 
                        scroll={{ x: 400 }} 
                        pagination={{ position: ['none', 'bottomLeft'] }}
                        rowKey="table1" 
                        columns={columns1}
                        onRow={(record, rowIndex) => {
                            return {
                                onClick: event => {conpush(record.id)}
                            };
                        }}
                    >
                    </Table>
                </Col>
            </Row>
        </React.Fragment>
    )
};

export default Global;