import React from 'react';
import MaskedInput from 'antd-mask-input'
import {SortableContainer, SortableElement, sortableHandle} from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { Result, Tooltip, Form, Input, Button, Divider, Row, Col, Radio, Space, Alert, Popover, Select, Modal, List, Typography, Popconfirm, Checkbox } from 'antd';
import { InfoCircleOutlined, PlusOutlined, DeleteOutlined, EditOutlined, ToolOutlined, MenuOutlined } from '@ant-design/icons';

const { TextArea } = Input;
const { Option } = Select;
const { Link, Text } = Typography;

const DragHandle = sortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />);

const Global = props => {
    const [form2] = Form.useForm();
    const [form3] = Form.useForm();
    const [form4] = Form.useForm();
    const [form5] = Form.useForm();
    const [form6] = Form.useForm();
    const [form7] = Form.useForm();
    const [form8] = Form.useForm();
    const [form9] = Form.useForm();

    const SortableItemRew = SortableElement(({value, newindex}) => 
        <List.Item className="listnofull" actions={[
            <Tooltip title={`Редактировать`}>
                <Link key="a1" onClick={() => revpush(value)}><EditOutlined /></Link>
            </Tooltip>,
            <Popconfirm placement="topRight" onConfirm={() => props.api({ 'method':'rev_del', 'revid':`${value.id}` },false)} 
                title={
                    <Space direction="vertical">
                        <Text>Вы действительно хотите удалить данный вопрос?</Text>
                        <Text>Все результаты, которые с ним связаны, также будут удалены.</Text>
                    </Space>
                } 
            okText="Да" cancelText="Нет">
                <Link key="a2" type="danger" ><DeleteOutlined /></Link>
            </Popconfirm>
        ]}>
            <List.Item.Meta
                title={<React.Fragment><Text strong>{newindex+1}.</Text> {value.text}</React.Fragment>} avatar={<DragHandle />}
            />
        
        </List.Item>
    );

    const SortableListRew = SortableContainer(({items}) => {
        return (
            <List
                className="listfull"
                itemLayout="horizontal"
            >
                {items.map((item, index) => (
                    <SortableItemRew key={`item-${item.id}`} index={index} newindex={index} value={item} />
                ))}
            </List>
        );
    });

    async function onSortEndRew ({oldIndex, newIndex}) {
        var reviews = props.state.reviews;
        reviews = arrayMove(props.state.reviews, oldIndex, newIndex);
        props.setState({
            reviews: reviews,
        });
        var v = {};
        v['method'] = 'rev_mv';
        for (var z = 0; z < reviews.length; z++) {
            v[`mv-${reviews[z]['id']}`] = z+1;
        }
        await props.api(v,false);
    };

    //@ Настройки
    //* Отправка формы настроек
    async function cladd(v) {
        v['method'] = 'settings_save';
        await props.api(v,false);
    }

    //@ Контакты
    //* Сохранение контактов
    async function contsave(v) {
        for( let value in v) {
            if(v[value] instanceof Array) {
               v[value] = v[value].join(',');
            }
        };
        v['method'] = 'cont_save';
        await props.api(v,false);
    }

    //@Платформы
    //* Вызов окна добавления платформы
    async function platpushadd() {
        await props.modal('plat_add');
    }

    //@ Профиль
    //* Сохранение пароля
    async function profilepass(v) {
        v['method'] = 'users_pasu';
        await props.api(v,false);
    }

    //@ SMS
    //* Сохранение данных SMS
    async function smssave(v) {
        v['method'] = 'sms_upd';
        await props.api(v,false);
    }

    //@ Вопросы
    //* Отправка формы вопроса
    async function platadd(v) {
        v['method'] = 'plat_add';
        await props.api(v,false);
    }

    //* Отправка формы вопроса
    async function revadd(v) {
        v['method'] = 'rev_add';
        var reviews = props.state.reviews;
        for (var z = 0; z < reviews.length; z++) {
            v[`mv-${reviews[z]['id']}`] = z+1;
        }
        await props.api(v,false);
    }

    //* Отправка формы вопроса
    async function revupd(v) {
        v['method'] = 'rev_upd';
        var reviews = props.state.reviews;
        for (var z = 0; z < reviews.length; z++) {
            v[`mv-${reviews[z]['id']}`] = z+1;
        }
        await props.api(v,false);
    }

    //* Добавление ответа в вопрос
    async function revplus() {
        let revitem= props.state.revitem;
        let id = 1;
        let text = 'Вариант';
        if(revitem.answers instanceof Array && revitem.answers.length > 0) {
            id = Number(indexOfMax(revitem.answers,'id'))+1;
            await revitem.answers.push({
                id: id,
                text: text
            });
        } else {
            revitem.answers = [];
            await revitem.answers.push({
                id: id,
                text: text
            });
        }
        await props.setState( { revitem:revitem } );
        await form3.setFieldsValue({ [`rev-ans-${id}`]:text }); 
    }

    function indexOfMax(arr,name) {
        if (arr.length === 0) {
            return -1;
        }
    
        var max = Number(arr[0][name]);
        var maxIndex = 0;
    
        for (var i = 1; i < arr.length; i++) {
            if (Number(arr[i][name]) > max) {
                maxIndex = i;
                max = arr[i][name];
            }
        }
    
        return max;
      }

    //* Удаление ответа из вопроса
    async function revdel(index) {
        let revitem = props.state.revitem;
        await revitem.answers.splice(index,1);
        await props.setState( { revitem:revitem } );
    }

    //* Изменение типа вопроса
    async function settype(v) {
        let revitem= props.state.revitem;
        revitem.type = v;
        await props.setState( { revitem:revitem } );
    }

    //* Вызов окна добавление вопроса
    async function revpushadd() {
        await form3.resetFields();
        await props.setState( { revitem:[] } );
        await props.modal('rev_add');
    }

    //* Вызов окна редактирования вопроса
    async function revpush(item) {
        await form3.resetFields();
        await props.setState( { revitem:item } );
        await form3.setFieldsValue({ revid:item.id, text:item.text, type:item.type }); 
        await props.modal('rev_upd');
        if (item.answers instanceof Array) {
            item.answers.map(list => {
                return form3.setFieldsValue({ [`rev-ans-${list.id}`]:list.text }); 
            });
        }
    }

    //* Отправка формы сотрудника
    async function persadd(v) {
        v['method'] = 'pers_add';
        await props.api(v,false);
    }

    //* Отправка формы сотрудника
    async function persupd(v) {
        v['method'] = 'pers_upd';
        await props.api(v,false);
    }

    //* Вызов окна добавление сотрудника
    async function perspushadd() {
        await form7.resetFields();
        await props.setState( { persitem:[] } );
        await props.modal('pers_add');
    }

    //* Вызов окна редактирования сотрудника
    async function perspush(item) {
        await form7.resetFields();
        await props.setState( { persitem:item } );
        await form7.setFieldsValue({ persid:item.id, name:item.name, type:item.type }); 
        await props.modal('pers_upd');
    }

    //* Отправка формы сотрудника
    async function usersadd(v) {
        v['method'] = 'users_add';
        v['phone'] = Number((v['phone']).replace(/\D+/g,""));
        await props.api(v,false);
    }

    //* Отправка формы сотрудника
    async function usersupd(v) {
        v['method'] = 'users_upd';
        v['phone'] = Number((v['phone']).replace(/\D+/g,""));
        await props.api(v,false);
    }

    //* Вызов окна добавление сотрудника
    async function userspushadd() {
        await form8.resetFields();
        await props.setState( { persitem:[] } );
        await props.modal('users_add');
    }

    //* Вызов окна редактирования сотрудника
    async function userspush(item) {
        await form8.resetFields();
        await props.setState( { usersitem:item } );
        await form8.setFieldsValue({ usersid:item.id, login:item.login, name:item.name, type:item.type, phone:item.phone }); 
        await props.modal('users_upd');
    }

    return (
        <React.Fragment>
            <Modal className="modal-mini bg_chester" key="m1" title="Список переменных" visible={props.state.modal === 'variable'} onOk={() => props.setState( { modal:'' } )} onCancel={() => props.setState( { modal:'' } )} footer={null}>
                <div className="modal-mini-header">
                    <div className="modal-mini-header-title">Список переменных</div>
                </div>
                <div className="modal-mini-body">
                    {!props.state.moreload || !props.state.loading ?
                        <List
                            itemLayout="horizontal"
                            dataSource={props.state.sms[1]}
                            renderItem={item => (
                            <List.Item>
                                <List.Item.Meta
                                title={`{${item.name}}`}
                                description={item.text}
                                />
                            </List.Item>
                            )}
                        />
                    :
                        <div className="spincenter"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
                    }
                </div>
            </Modal>
            <Modal className="modal-mini bg_chester" key="m2" title="Добавление платформы" visible={props.state.modal === 'plat_add'} onOk={() => props.setState( { modal:'' } )} onCancel={() => props.setState( { modal:'' } )} footer={null}>
                <div className="modal-mini-header">
                    <div className="modal-mini-header-title">Добавление платформы</div>
                </div>
                <div className="modal-mini-body">
                    {!props.state.moreload || !props.state.loading ?
                        <Form name="form" form={form4} onFinish={platadd} className="form" layout="vertical">
                            <Form.Item name={`name`} label='Название' rules={[{ required: true, message: `Пожалуйста, введите название!` }]}>
                                <Input suffix={<Tooltip title={`Отображаемое название`}><InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} /></Tooltip>} />
                            </Form.Item>
                            <Form.Item name={`url`} label='Ссылка' rules={[{ required: true, message: `Пожалуйста, введите ссылку на платформу!` }]}>
                                <Input suffix={<Tooltip title={`Ссылка на платформу`}><InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} /></Tooltip>} />
                            </Form.Item>
                            <Divider />
                            <div className="fcenter">
                                <Form.Item>
                                    <Button className="des-button" type="dashed" shape="round" htmlType="submit">Сохранить</Button>
                                </Form.Item>
                            </div>
                        </Form>
                    :
                        <div className="spincenter"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
                    }
                </div>
            </Modal>
            <Modal className="modal-mini bg_chester" key="m3" title="Добавление вопроса" visible={props.state.modal === 'rev_add'} onOk={() => props.setState( { modal:'' } )} onCancel={() => props.setState( { modal:'' } )} footer={null}>
                <div className="modal-mini-header">
                    <div className="modal-mini-header-title">Добавление вопроса</div>
                </div>
                <div className="modal-mini-body">
                    {!props.state.moreload || !props.state.loading ?
                        <Form name="form" form={form3} onFinish={revadd} className="form" layout="vertical">
                            <Form.Item name={`text`} label='Вопрос' rules={[{ required: true, message: `Пожалуйста, введите вопрос!` }]}>
                                <Input suffix={<Tooltip title={`Текст вопроса`}><InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} /></Tooltip>} />
                            </Form.Item>
                            <Form.Item name={`type`} label='Тип' rules={[{ required: true, message: `Пожалуйста, выберите тип вопроса!` }]}>
                                <Select onChange={settype}>
                                    <Option value="1">Свой ответ</Option>
                                    <Option value="2">Один вариант ответа</Option>
                                    <Option value="3">Несколько вариантов ответа</Option>
                                    <Option value="4">Рейтинг</Option>
                                </Select>
                            </Form.Item>
                            {props.state.revitem.type === '1' &&
                                <React.Fragment>
                                    <Alert type="info" showIcon={false} message="Вопрос со своим вариантом ответа" banner />
                                    {
                                        //@ Ничего не показываем
                                    }
                                </React.Fragment>
                            }
                            { props.state.revitem.type === '2' &&
                                <Alert type="info" showIcon={false} message="Вопрос с одним вариантом ответа" banner />
                            }
                            { props.state.revitem.type === '3' &&
                                <Alert type="info" showIcon={false} message="Вопрос с несколькими вариантами ответа" banner />
                            }
                            {(props.state.revitem.type === '2' || props.state.revitem.type === '3') &&
                                <React.Fragment>
                                    <Divider key="d1" orientation="left">Ответы</Divider>
                                    {
                                        props.state.revitem.answers instanceof Array &&
                                            <React.Fragment>
                                                {
                                                    props.state.revitem.answers.map( (list, index) => 
                                                        <Form.Item name={`rev-ans-${list.id}`} rules={[{ required: true, message: `Пожалуйста, введите ответ!` }]}>
                                                            <Input suffix={
                                                                <Tooltip title={`Удалить`}>
                                                                    <Link type="danger" onClick={() => revdel(index)} key="a1"><DeleteOutlined /></Link>
                                                                </Tooltip>
                                                            }
                                                                />
                                                        </Form.Item>
                                                    )
                                                }
                                            </React.Fragment>
                                        }
                                        <div className="fcenter">
                                            <Button type="dashed" shape="circle" icon={<PlusOutlined />} onClick={() => revplus()} />
                                        </div>
                                </React.Fragment>
                            }
                            { props.state.revitem.type === '4' &&
                                <Alert type="info" showIcon={false} message="Вопрос с выбором оценки" banner />
                            }
                            <Divider />
                            <div className="fcenter">
                                <Form.Item>
                                    <Button className="des-button" type="dashed" shape="round" htmlType="submit">Сохранить</Button>
                                </Form.Item>
                            </div>
                        </Form>
                    :
                        <div className="spincenter"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
                    }
                </div>
            </Modal>
            <Modal className="modal-mini bg_chester" key="m4" title="Редактирование вопроса" visible={props.state.modal === 'rev_upd'} onOk={() => props.setState( { modal:'' } )} onCancel={() => props.setState( { modal:'' } )} footer={null}>
                <div className="modal-mini-header">
                    <div className="modal-mini-header-title">Редактирование вопроса</div>
                </div>
                <div className="modal-mini-body">
                    {!props.state.moreload || !props.state.loading ?
                        <Form name="form" form={form3} onFinish={revupd} className="form" layout="vertical">
                            <Form.Item name={`text`} label='Вопрос' rules={[{ required: true, message: `Пожалуйста, введите вопрос!` }]}>
                                <Input suffix={<Tooltip title={`Текст вопроса`}><InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} /></Tooltip>} />
                            </Form.Item>
                            <Form.Item hidden name={`revid`} label='ID' rules={[{ required: true, message: `Пожалуйста, введите ID!` }]}>
                                <Input suffix={<Tooltip title={`ID`}><InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} /></Tooltip>} />
                            </Form.Item>
                            {props.state.revitem.type === '1' &&
                                <React.Fragment>
                                    <Alert type="info" showIcon={false} message="Вопрос со своим вариантом ответа" banner />
                                    {
                                        //@ Ничего не показываем
                                    }
                                </React.Fragment>
                            }
                            { props.state.revitem.type === '2' &&
                                <Alert type="info" showIcon={false} message="Вопрос с одним вариантом ответа" banner />
                            }
                            { props.state.revitem.type === '3' &&
                                <Alert type="info" showIcon={false} message="Вопрос с несколькими вариантами ответа" banner />
                            }
                            {(props.state.revitem.type === '2' || props.state.revitem.type === '3') &&
                                <React.Fragment>
                                    <Divider key="d1" orientation="left">Ответы</Divider>
                                    {
                                        props.state.revitem.answers instanceof Array &&
                                            <React.Fragment>
                                                {
                                                    props.state.revitem.answers.map( (list, index) => 
                                                        <Form.Item name={`rev-ans-${list.id}`} rules={[{ required: true, message: `Пожалуйста, введите ответ!` }]}>
                                                            <Input suffix={
                                                                <Tooltip title={`Удалить`}>
                                                                    <Link type="danger" onClick={() => revdel(index)} key="a1"><DeleteOutlined /></Link>
                                                                </Tooltip>
                                                            }
                                                                />
                                                        </Form.Item>
                                                    )
                                                }
                                            </React.Fragment>
                                        }
                                        <div className="fcenter">
                                            <Button type="dashed" shape="circle" icon={<PlusOutlined />} onClick={() => revplus()} />
                                        </div>
                                </React.Fragment>
                            }
                            { props.state.revitem.type === '4' &&
                                <Alert type="info" showIcon={false} message="Вопрос с выбором оценки" banner />
                            }
                            <Divider />
                            <div className="fcenter">
                                <Form.Item>
                                    <Button className="des-button" type="dashed" shape="round" htmlType="submit">Сохранить</Button>
                                </Form.Item>
                            </div>
                            
                        </Form>
                    :
                        <div className="spincenter"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
                    }
                </div>
            </Modal>
            <Modal className="modal-mini bg_chester" key="m5" title="Добавление сотрудника" visible={props.state.modal === 'pers_add'} onOk={() => props.setState( { modal:'' } )} onCancel={() => props.setState( { modal:'' } )} footer={null}>
                <div className="modal-mini-header">
                    <div className="modal-mini-header-title">Добавление сотрудника</div>
                </div>
                <div className="modal-mini-body">
                    {!props.state.moreload || !props.state.loading ?
                        <Form name="form" form={form7} onFinish={persadd} className="form" layout="vertical">
                            <Form.Item name={`name`} label='ФИО' rules={[{ required: true, message: `Пожалуйста, введите ФИО сотрудника!` }]}>
                                <Input suffix={<Tooltip title={`ФИО сотрудника`}><InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} /></Tooltip>} />
                            </Form.Item>
                            <Form.Item name={`type`} label='Должность' rules={[{ required: true, message: `Пожалуйста, выберите должность сотрудника!` }]}>
                                <Select onChange={settype}>
                                    <Option value="1">Администратор</Option>
                                    <Option value="2">Сотрудник</Option>
                                </Select>
                            </Form.Item>
                            <Divider />
                            <div className="fcenter">
                                <Form.Item>
                                    <Button className="des-button" type="dashed" shape="round" htmlType="submit">Сохранить</Button>
                                </Form.Item>
                            </div>
                        </Form>
                    :
                        <div className="spincenter"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
                    }
                </div>
            </Modal>
            <Modal className="modal-mini bg_chester" key="m6" title="Редактирование сотрудника" visible={props.state.modal === 'pers_upd'} onOk={() => props.setState( { modal:'' } )} onCancel={() => props.setState( { modal:'' } )} footer={null}>
                <div className="modal-mini-header">
                    <div className="modal-mini-header-title">Редактирование сотрудника</div>
                </div>
                <div className="modal-mini-body">
                    {!props.state.moreload || !props.state.loading ?
                        <Form name="form" form={form7} onFinish={persupd} className="form" layout="vertical">
                            <Form.Item name={`name`} label='ФИО' rules={[{ required: true, message: `Пожалуйста, введите ФИО сотрудника!` }]}>
                                <Input suffix={<Tooltip title={`ФИО сотрудника`}><InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} /></Tooltip>} />
                            </Form.Item>
                            <Form.Item hidden name={`persid`} label='ID' rules={[{ required: true, message: `Пожалуйста, введите ID!` }]}>
                                <Input suffix={<Tooltip title={`ID`}><InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} /></Tooltip>} />
                            </Form.Item>
                            <Form.Item name={`type`} label='Должность' rules={[{ required: true, message: `Пожалуйста, выберите должность сотрудника!` }]}>
                                <Select onChange={settype}>
                                    <Option value="1">Администратор</Option>
                                    <Option value="2">Сотрудник</Option>
                                </Select>
                            </Form.Item>
                            <Divider />
                            <div className="fcenter">
                                <Form.Item>
                                    <Button className="des-button" type="dashed" shape="round" htmlType="submit">Сохранить</Button>
                                </Form.Item>
                            </div>
                        </Form>
                    :
                        <div className="spincenter"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
                    }
                </div>
            </Modal>
            <Modal className="modal-mini bg_chester" key="m7" title="Добавление сотрудника" visible={props.state.modal === 'users_add'} onOk={() => props.setState( { modal:'' } )} onCancel={() => props.setState( { modal:'' } )} footer={null}>
                <div className="modal-mini-header">
                    <div className="modal-mini-header-title">Добавление сотрудника</div>
                </div>
                <div className="modal-mini-body">
                    {!props.state.moreload || !props.state.loading ?
                        <Form name="form" form={form8} onFinish={usersadd} className="form" layout="vertical">
                            <Form.Item name={`login`} label='Логин' rules={[{ required: true, message: `Пожалуйста, придумайте логин сотрудника!` }]}>
                                <Input suffix={<Tooltip title={`Логин сотрудника`}><InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} /></Tooltip>} />
                            </Form.Item>
                            <Form.Item name={`name`} label='ФИО' rules={[{ required: true, message: `Пожалуйста, введите ФИО сотрудника!` }]}>
                                <Input suffix={<Tooltip title={`ФИО сотрудника`}><InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} /></Tooltip>} />
                            </Form.Item>
                            <Form.Item name={`phone`} label='Телефон' rules={[{ required: true, message: `Пожалуйста, введите номер телефона сотрудника!` }]}>
                                <MaskedInput
                                    prefix="+"
                                    mask="7 111-111-11-11"
                                    placeholder="7 999-999-99-99"
                                    size="11"
                                    formatCharacters={{
                                    'W': {
                                        validate(char) { return /\w/.test(char ) },
                                        transform(char) { return char.toUpperCase() }
                                    }
                                    }}
                                />
                            </Form.Item>
                            <Form.Item name={`type`} label='Должность' rules={[{ required: true, message: `Пожалуйста, выберите должность сотрудника!` }]}>
                                <Select onChange={settype}>
                                    <Option value="1">Руковлдитель</Option>
                                    <Option value="2">Администратор</Option>
                                    <Option value="3">Врач</Option>
                                    <Option value="4">Ассистент врача</Option>
                                </Select>
                            </Form.Item>
                            <Divider />
                            <div className="fcenter">
                                <Form.Item>
                                    <Button className="des-button" type="dashed" shape="round" htmlType="submit">Сохранить</Button>
                                </Form.Item>
                            </div>
                        </Form>
                    :
                        <div className="spincenter"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
                    }
                </div>
            </Modal>
            <Modal className="modal-mini bg_chester" key="m8" title="Редактирование сотрудника" visible={props.state.modal === 'users_upd'} onOk={() => props.setState( { modal:'' } )} onCancel={() => props.setState( { modal:'' } )} footer={null}>
                <div className="modal-mini-header">
                    <div className="modal-mini-header-title">Редактирование сотрудника</div>
                </div>
                <div className="modal-mini-body">
                    {!props.state.moreload || !props.state.loading ?
                        <Form name="form" form={form8} onFinish={usersupd} className="form" layout="vertical">
                            <Form.Item name={`login`} label='Логин' rules={[{ required: true, message: `Пожалуйста, придумайте логин сотрудника!` }]}>
                                <Input disabled suffix={<Tooltip title={`Логин сотрудника`}><InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} /></Tooltip>} />
                            </Form.Item>
                            <Form.Item name={`name`} label='ФИО' rules={[{ required: true, message: `Пожалуйста, введите ФИО сотрудника!` }]}>
                                <Input suffix={<Tooltip title={`ФИО сотрудника`}><InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} /></Tooltip>} />
                            </Form.Item>
                            <Form.Item name={`phone`} label='Телефон' rules={[{ required: true, message: `Пожалуйста, введите номер телефона сотрудника!` }]}>
                                <MaskedInput
                                    prefix="+"
                                    mask="7 111-111-11-11"
                                    placeholder="7 999-999-99-99"
                                    size="11"
                                    formatCharacters={{
                                    'W': {
                                        validate(char) { return /\w/.test(char ) },
                                        transform(char) { return char.toUpperCase() }
                                    }
                                    }}
                                />
                            </Form.Item>
                            <Form.Item hidden name={`usersid`} label='ID' rules={[{ required: true, message: `Пожалуйста, введите ID!` }]}>
                                <Input suffix={<Tooltip title={`ID`}><InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} /></Tooltip>} />
                            </Form.Item>
                            <Form.Item name={`type`} label='Должность' rules={[{ required: true, message: `Пожалуйста, выберите должность сотрудника!` }]}>
                                <Select onChange={settype}>
                                    <Option value="1">Руководитель</Option>
                                    <Option value="2">Администратор</Option>
                                    <Option value="3">Врач</Option>
                                    <Option value="4">Ассистент врача</Option>
                                </Select>
                            </Form.Item>
                            <Divider />
                            <div className="fcenter">
                                <Form.Item>
                                    <Button className="des-button" type="dashed" shape="round" htmlType="submit">Сохранить</Button>
                                </Form.Item>
                            </div>
                        </Form>
                    :
                        <div className="spincenter"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
                    }
                </div>
            </Modal>
            <Row style={{flexDirection:'column'}} className="column900 wowload">
                { form2.setFieldsValue({ active:props.state.settings[1] })}
                {
                        props.state.settings[0].map(list => {
                            return form2.setFieldsValue({ [`platform-${list.id}`]:list.url }); 
                        })
                }
                { form5.setFieldsValue({ conta:props.state.contacts[1] }) }
                {
                        props.state.contacts[0].map(list => {
                            return form5.setFieldsValue({ [`cont-${list.id}`]:list.text }); 
                        })
                }
                {
                        props.state.sms[0].map(list => {
                            return form6.setFieldsValue({ [`sms-${list.name}`]:list.text }); 
                        })
                }
                {!Number(props.state.balance) &&
                    <Col style={{padding:20}}>
                        <div className="fcenter">
                            <Alert
                                style={{maxWidth:400}}
                                banner
                                showIcon={false}
                                message={
                                <React.Fragment>
                                    Внимание! Данные от SMS сервиса указаны неверно, либо баланс равен 0.
                                </React.Fragment>
                                }
                            />
                        </div>
                    </Col>
                }
                <Col key="k0_profile" className="shadowscreen" >
                    <div className="header_title">Учётная запись</div>
                    <div style={{padding:20}}>
                        <Form name="form" form={form9} onFinish={profilepass} className="form">
                            <Form.Item name='pass' label="Пароль">
                                <Input.Password />
                            </Form.Item>
                            <Divider />
                            <div className="fcenter">
                                <Form.Item>
                                    <Button className="des-button" type="dashed" shape="round" htmlType="submit">Сохранить</Button>
                                </Form.Item>
                            </div>
                        </Form>
                    </div>
                </Col>
                { (props.state.da.type === "1" || props.state.da.type === "999") && 
                    <Col key="k1_actplatform" className="shadowscreen" >
                        <div className="header_title">Активная платформа</div>
                        <div style={{padding:20}} >
                            <Form name="form" form={form2} onFinish={cladd} className="form">
                                {(props.state.settings[1] === '' && props.state.settings[0].length > 0) &&
                                    <Alert
                                        style={{marginBottom:20}}
                                        message="Внимание!"
                                        description="У вас не выбрана активная платформа для отзывов, пожалуйста исправьте это."
                                        type="warning"
                                    />
                                }
                                <Form.Item name='active'>
                                    <Radio.Group>
                                        <Space direction="horizontal" wrap>
                                            { props.state.settings[0].map( (item, index) => 
                                                <Radio key={index} disabled={Number(item.block)} value={item.id}>{item.name}</Radio>
                                            ) }
                                        </Space>
                                    </Radio.Group>
                                </Form.Item>
                                <Divider key="d2" orientation="left">Ссылки на платформы</Divider>
                                { props.state.settings[0].map( (item, index) => 
                                    <Form.Item key={index} name={`platform-${item.id}`} label={item.name} rules={[{ required: true, message: `Пожалуйста, укажите ссылку на ${item.name}!` }]}>
                                        <Input suffix={
                                            <Popconfirm placement="topRight" onConfirm={() => props.api({ 'method':'plat_del', 'platid':`${item.id}` },false)} 
                                                title={
                                                    <Space direction="vertical">
                                                        <Text>Вы действительно хотите удалить данную платформу?</Text>
                                                    </Space>
                                                } 
                                            okText="Да" cancelText="Нет">
                                                <Link key="a2" type="danger"><DeleteOutlined /></Link>
                                            </Popconfirm>
                                        } />
                                    </Form.Item>
                                ) }
                                <div className="fcenter">
                                    <Button className="des-button" type="dashed" shape="circle" icon={<PlusOutlined />} onClick={() => platpushadd()} />
                                </div>
                                <Divider />
                                <div className="fcenter">
                                    <Form.Item>
                                        <Button className="des-button" type="dashed" shape="round" htmlType="submit">Сохранить</Button>
                                    </Form.Item>
                                </div>
                            </Form>
                        </div>
                    </Col>
                }
                { (props.state.da.type === "1" || props.state.da.type === "999") && 
                    <Col key="k2_reviews" className="shadowscreen" >
                        <div className="header_title">Список вопросов</div>
                        <div style={{padding:20}} >
                            <Alert message="На сколько Вам понравилось посещение клиники?" type="info"  
                                action={
                                    <Popover
                                        content={
                                            'Данный вопрос уже добавлен, пожалуйста не создавайте подобный самостоятельно. У вопроса стоит тип "с выбором оценки".'
                                        }
                                        title="Важно!"
                                        trigger="click"
                                    >
                                        <Link key="a1"><InfoCircleOutlined /></Link>
                                    </Popover>
                                } 
                            />
                            <SortableListRew items={props.state.reviews} onSortEnd={onSortEndRew} useDragHandle axis="y" lockAxis="y"  />
                            <div className="fcenter">
                                <Button className="des-button" type="dashed" shape="circle" icon={<PlusOutlined />} onClick={() => revpushadd()} />
                            </div>
                        </div>
                    </Col>
                }
                { (props.state.da.type === "1" || props.state.da.type === "999") && 
                    <Col key="k3_contadmin" className="shadowscreen" >
                        <div className="header_title">Рассылка администрации</div>
                        <div style={{padding:20}} >
                            <Form name="form" form={form5} onFinish={contsave} className="form">
                                    <Form.Item key="conta" name="conta">
                                        <Checkbox.Group>
                                            <Space direction="vertical">
                                                { props.state.contacts[0].map( (item, index) => 
                                                    <Checkbox value={item.id} >{item.name}</Checkbox>
                                                ) }
                                            </Space>
                                        </Checkbox.Group>
                                    </Form.Item>
                                <Divider key="d2" orientation="left">Параметры</Divider>
                                { props.state.contacts[0].map( (item, index) => 
                                    <Form.Item key={index} name={`cont-${item.id}`} label={item.name}>
                                        <Input />
                                    </Form.Item>
                                ) }
                                <Divider />
                                <div className="fcenter">
                                    <Form.Item>
                                        <Button className="des-button" type="dashed" shape="round" htmlType="submit">Сохранить</Button>
                                    </Form.Item>
                                </div>
                            </Form>
                        </div>
                    </Col>
                }

                {(props.state.da.type === "1" || props.state.da.type === "999") && 
                    <Col key="k4_smssettings" className="shadowscreen" >
                        <Form name="form" form={form6} onFinish={smssave} className="form">
                            <div className="header_title">Настройка SMS сервиса</div>
                            <div style={{padding:20}} >
                                {
                                    props.state.sms[0].map( (list, index) => 
                                        <React.Fragment>
                                            {list.type === 'input' &&
                                                <Form.Item name={`sms-${list.name}`} label={list.comment}>
                                                    <Input />
                                                </Form.Item>
                                            }
                                            {list.type === 'textarea' &&
                                                <Form.Item name={`sms-${list.name}`} label={list.comment}>
                                                    <TextArea showCount maxLength={150} />
                                                </Form.Item>
                                            }
                                            {list.type === 'password' &&
                                                <Form.Item name={`sms-${list.name}`} label={list.comment}>
                                                    <Input.Password />
                                                </Form.Item>
                                            }
                                        </React.Fragment>
                                    )
                                }
                                <Space direction="vertical">
                                    <Text><Link onClick={() => props.modal('variable')}>Список переменных</Link>, которые можно использовать в сообщениях.</Text>
                                </Space>
                                <Divider />
                                <div className="fcenter">
                                    <Form.Item>
                                        <Button type="dashed" shape="round" htmlType="submit">Сохранить</Button>
                                    </Form.Item>
                                </div>
                            </div>
                        </Form>
                    </Col>
                }
                { (props.state.da.type === "1" || props.state.da.type === "999") && 
                    <Col key="k4_emplist" className="shadowscreen" >
                        <div className="header_title">Сотрудники для опросов</div>
                        <div style={{padding:20}} >
                            <List
                                itemLayout="horizontal"
                                dataSource={props.state.pers}
                                renderItem={item => (
                                <List.Item actions={[
                                    <Tooltip title={`Редактировать`}>
                                        <Link key="a1" onClick={() => perspush(item)}><EditOutlined /></Link>
                                    </Tooltip>,
                                    <Popconfirm placement="topRight" onConfirm={() => props.api({ 'method':'pers_del', 'persid':`${item.id}` },false)} 
                                        title={
                                            <Space direction="vertical">
                                                <Text>Вы действительно хотите удалить данного сотрудника?</Text>
                                                <Text>Все результаты, которые с ним связаны, также будут удалены.</Text>
                                            </Space>
                                        } 
                                    okText="Да" cancelText="Нет">
                                        <Link type="danger" key="a1"><DeleteOutlined /></Link>
                                    </Popconfirm>
                                ]}>
                                    <List.Item.Meta
                                    title={item.name}
                                    description={item.role}
                                    />
                                </List.Item>
                                )}
                            />
                            <div className="fcenter">
                                <Button className="des-button" type="dashed" shape="circle" icon={<PlusOutlined />} onClick={() => perspushadd()} />
                            </div>
                        </div>
                    </Col>
                }
                {(props.state.da.type === "1" || props.state.da.type === "999") && 
                    <Col key="k5_userlist" className="shadowscreen" >
                        <div className="header_title">Учетные записи сотрудников</div>
                        <div style={{padding:20}} >
                            <List
                                itemLayout="horizontal"
                                dataSource={props.state.users}
                                renderItem={item => (
                                <List.Item actions={[
                                    <Tooltip title={`Редактировать`}>
                                        <Link key="a1" onClick={() => userspush(item)}><EditOutlined /></Link>
                                    </Tooltip>,
                                    <Tooltip title={`Обновить пароль`}>
                                        <Popconfirm placement="topRight" onConfirm={() => props.api({ 'method':'users_pas', 'usersid':`${item.id}` },false)} 
                                            title={
                                                <Space direction="vertical">
                                                    <Text>Вы действительно хотите обновить пароль сотрудника?</Text>
                                                </Space>
                                            } 
                                        okText="Да" cancelText="Нет">
                                            <Link key="a2"><ToolOutlined /></Link>
                                        </Popconfirm>
                                    </Tooltip>,
                                    <Popconfirm placement="topRight" onConfirm={() => props.api({ 'method':'users_del', 'usersid':`${item.id}` },false)} 
                                        title={
                                            <Space direction="vertical">
                                                <Text>Вы действительно хотите удалить данного сотрудника?</Text>
                                            </Space>
                                        } 
                                    okText="Да" cancelText="Нет">
                                        <Link type="danger" key="a1"><DeleteOutlined /></Link>
                                    </Popconfirm>
                                ]}>
                                    <List.Item.Meta
                                    title={item.name}
                                    description={item.role}
                                    />
                                </List.Item>
                                )}
                            />
                            <div className="fcenter">
                                <Button className="des-button" type="dashed" shape="circle" icon={<PlusOutlined />} onClick={() => userspushadd()} />
                            </div>
                        </div>
                    </Col>
                }
            </Row>
        </React.Fragment>
    )
};

export default Global;